import {
  Business,
  Email,
  Group,
  Language,
  LocationOn,
  Phone,
  Work,
} from "@mui/icons-material";
import axiosConfig from "../../providers/axiosConfig";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Buttons } from "./buttons";
import { PhotoInput, TextInput } from "./inputs";
import SideBar from "./sidebar";
const EditBusiness = () => {
  // const [client_id, setClientId] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [company_area, setCompanyArea] = useState("");
  const [company_address, setCompanyAddress] = useState("");
  const [company_nif, setCompanyNif] = useState("");
  const [company_phone, setCompanyPhone] = useState(0);
  const [company_website, setCompanieWebsite] = useState("");
  const [company_email, setCompanyEmail] = useState("");
  const [company_size, setCompanySize] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axiosConfig.get("/me").then((res) => {
      // setClientId(res.data.id);
      setCompanyId(location.state.selected_company);
    });
  }, [location.state.selected_company]);

  useEffect(() => {
    (async () => {
      if (company_id) {
        await axiosConfig.get("/company/" + company_id).then((res) => {
          setCompanyName(res.data.name);
          setCompanyEmail(res.data.email);
          setCompanieWebsite(res.data.website);
          setCompanyAddress(res.data.address);
          setCompanyNif(res.data.nif);
          setCompanyArea(res.data.area);
          setCompanyPhone(res.data.phone_number);
          setCompanySize(res.data.range);
        });
      }
    })();
  }, [company_id]);

  function EditBusinessApi(event) {
    event.preventDefault();
    const business_data = {
      name: company_name,
      email: company_email,
      address: company_address,
      phone_number: company_phone,
      nif: company_nif,
      area: company_area,
      website: company_website,
      size: company_size,
    };
    axiosConfig.put("/company/" + company_id, business_data).then((res) => {
      navigate("/dashboard");
    });
  }
  return (
    <main className="ovr-hd is-fullheight">
      <section className="is-section is-fullheight flex-column is-centered">
        <form
          className="box-default p20 is-fullwidth"
          onSubmit={EditBusinessApi}
        >
          <h1>Editar Empresa</h1>
          <div className="flex-column g20 is-grow   ">
            <div className="flex-column is-centered round-img big b1-white">
              <PhotoInput />
            </div>
            <div className="flex-row is-fullwidth g20">
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  value={company_name}
                  icon={<Business />}
                  onChange={(event) => {
                    setCompanyName(event.target.value);
                  }}
                />
              </div>
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  value={company_email}
                  icon={<Email />}
                  onChange={(event) => {
                    setCompanyEmail(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row is-fullwidth g20">
              <div className="is-grow">
                <div className="input-secondary input-adaptive">
                  <select
                    className="input-default"
                    placeholder="Area"
                    defaultValue={company_area}
                    onChange={(event) => {
                      setCompanyArea(event.target.value);
                    }}
                  >
                    <option value={"sales"}>Sales</option>
                    <option value={"marketing"}>Marketing</option>
                    <option value={"engineer"}>Engineer</option>
                    <option value={"design"}>Design</option>
                    <option value={"other"}>Other</option>
                  </select>
                  <div className="input-icon">
                    <Work />
                  </div>
                </div>
              </div>
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  value={company_nif}
                  icon={<Language />}
                  onChange={(event) => {
                    setCompanyNif(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row is-fullwidth g20">
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  value={company_size}
                  icon={<Group />}
                  onChange={(event) => {
                    setCompanySize(event.target.value);
                  }}
                />
              </div>
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  value={company_address}
                  icon={<LocationOn />}
                  onChange={(event) => {
                    setCompanyAddress(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row is-fullwidth g20">
              <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  value={company_website}
                  icon={<Language />}
                  onChange={(event) => {
                    setCompanieWebsite(event.target.value);
                  }}
                />
              </div>
              <div className="is-grow">
                <TextInput
                  type={"number"}
                  inputSize={"input-adaptive"}
                  value={company_phone}
                  icon={<Phone />}
                  onChange={(event) => {
                    setCompanyPhone(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row is-nowrap is-fullwidth g20 text-separation-small">
              <div className="is-grow">
                <Buttons
                  type={"button"}
                  buttonStyle={"btn-secondary"}
                  buttonSize={"btn-adaptive"}
                  value={"Cancelar"}
                />
              </div>
              <div className="is-grow">
                <Buttons
                  type={"submit"}
                  value={"Guardar"}
                  buttonSize={"btn-adaptive"}
                />
              </div>
            </div>
          </div>
        </form>
      </section>
      <SideBar />
    </main>
  );
};

export default EditBusiness;
