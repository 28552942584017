import React, { useEffect, useState } from "react";
import "../css/main.css";
import LogoIcon from "../assets/img/logo_icon.svg";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Buttons } from "../assets/components/buttons";
import { useNavigate } from "react-router-dom";
import { ColorInput, FileInput, TextInput } from "../assets/components/inputs";
import { Add, ColorLens, Group, Help, Remove } from "@mui/icons-material";
import CardDefault from "../assets/cards/card_default";
import CardTemplateButton from "../assets/components/cardtemplateButton";
import { ReactComponent as Lines } from "../assets/img/bg_lines.svg";
import axiosConfig from "../providers/axiosConfig";
import moment from "moment";

const CreateCard = () => {
  const [screen, setScreen] = useState(true);
  const [screen2, setScreen2] = useState(false);
  const [screen3, setScreen3] = useState(false);
  const [screen4, setScreen4] = useState(false);
  const [screen5, setScreen5] = useState(false);
  const [social_dropdown, setDropdown] = useState(false);
  const [layoutTemplate, setLayout] = useState();

  // const [vertical, setVertical] = useState(true);
  // const [horizontal, setHorizontal] = useState(false);

  let vertical = true;
  // let horizontal = true;
  const [client_id, setClientId] = useState("");
  // const [direction, setDirection] = useState("");
  // const [template_id, setTemplateId] = useState("");

  const [herocolor, setHeroColor] = useState("");
  const [textcolor, setTextColor] = useState("");
  const [iconcolor, setIconColor] = useState("");
  const [titlecolor, setTitleColor] = useState("");

  const [logoimage, setLogo] = useState([]);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [cargo, setCargo] = useState("");

  const [email_personal, setEmailPersonal] = useState("");
  const [email_business, setEmailBusiness] = useState("");
  const [phone_personal, setPhonePersonal] = useState("");
  const [phone_business, setPhoneBusiness] = useState("");
  const [phone_alternative, setPhoneAlternative] = useState("");
  const [address_hq, setAddressHQ] = useState("");
  const [address, setAddress] = useState("");

  const [website, setWebsite] = useState("");
  // const [emails, setEmails] = useState({
  //   email: "",
  //   emails: [{ email: "" }],
  // });
  // const [phones, setPhones] = useState({
  //   phone: null,
  //   phones: [{ phone_number: null }],
  // });

  // const [addresses, setAddress] = useState({
  //   address: "",
  //   addresses: [{ address: "" }],
  // });

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [skype, setSkype] = useState("");
  const [youtube, setYoutube] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [socials, setSocial] = useState([]);
  const [facebookURL, setFacebookURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [skypeURL, setSkypeURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [whatsappURL, setWhatsappURL] = useState("");
  const [socialArray, setSocialArray] = useState([""], [""]);

  // const [id_user, setUser] = useState("");
  // const [id_card, setCard] = useState("");
  const [customizable, setCustomizable] = useState(null);
  // let logoimageurl;

  const navigate = useNavigate();
  const data = {
    direction: "vertical",
    template_id: 1,
    background_color: herocolor,
    text_color: textcolor,
    icon_color: iconcolor,
    hero_color: titlecolor,
  };

  useEffect(() => {
    axiosConfig.get("/me").then((res) => {
      setClientId(res.data.client_id);
      setFirstName(res.data.first_name);
      setLastName(res.data.last_name);
      setCargo(res.data.post);

      // const newemails = emails.emails.map((email, sidx) => {
      //   if (0 !== sidx) return email;
      //   return { ...email, email: res.data.email };
      // });
      // setEmails({ emails: newemails });

      // const newphones = phones.phones.map((phone, sidx) => {
      //   if (0 !== sidx) return phone;
      //   return { ...phone, phone_number: res.data.phone };
      // });
      // setPhones({ phones: newphones });

      // const newadresses = addresses.addresses.map((address, sidx) => {
      //   if (0 !== sidx) return address;
      //   return { ...address, address: res.data.adress };
      // });
      // setAddress({ addresses: newadresses });
    });
  }, []);
  useEffect(() => {
    if (customizable !== null) {
      const userData = {
        client_id: client_id,
        customizable_id: customizable,
        first_name: firstname,
        last_name: lastname,
        post: cargo,
        email_business: email_business,
        email_personal: email_personal,
        phone_business: phone_business,
        phone_personal: phone_personal,
        phone_alternative: phone_alternative,
        address_hq: address_hq,
        address: address,
      };
      axiosConfig.get("/me/subscription").then((res) => {
        if (res.data.subscription !== null) {
          (async () => {
            await axiosConfig
              .post("/cards", userData)
              .then(() => navigate("/dashboard/"));
          })();
        } else {
          (async () => {
            await axiosConfig
              .post("/cards", userData)
              .then(() => navigate("/dashboard/"));
          })();
        }
      });
    }
  }, [
    customizable,
    address,
    address_hq,
    phone_alternative,
    phone_personal,
    phone_business,
    email_business,
    email_personal,
    cargo,
    client_id,
    firstname,
    lastname,
    navigate,
  ]);
  useEffect(() => {}, [
    firstname,
    lastname,
    cargo,
    textcolor,
    herocolor,
    titlecolor,
    iconcolor,
    socialArray,
  ]);
  function SetLayoutCard(cardTemplate) {
    setLayout(cardTemplate);
  }
  async function SendtoApi(event) {
    event.preventDefault();
    axiosConfig.get("/me").then((res) => {
      setClientId(res.data.id);
      axiosConfig.post("/customizable", data).then((res) => {
        setCustomizable(res.data.id);
      });
    });
  }
  // function ToggleOrientation(e) {
  //   if (e.id === "vertical-btn") {
  //     setVertical(true);
  //     setHorizontal(false);
  //   } else if (e.id === "horizontal-btn") {
  //     setVertical(false);
  //     setHorizontal(true);
  //   }
  // }
  function ThemeSettings() {
    return (
      <div className="content-sidebar text-left p-20 gap-20">
        <h3>Modelos</h3>
        <div className="flex-row">
          <div
            id="vertical-btn"
            className={`btn toggle ${vertical ? "active" : ""}`}
            // onClick={(e) => ToggleOrientation(e.target)}
          >
            Vertical
          </div>
          {/* <div
            id="horizontal-btn"
            className={`btn toggle ${horizontal ? "active" : ""}`}
            // onClick={(e) => ToggleOrientation(e.target)}
          >
            Horizontal
          </div> */}
        </div>
        <div className="container-items">
          <div
            id="vertical"
            className="flex-row gap-20 flex-align-flexstart active"
            style={vertical ? { display: "flex" } : { display: "none" }}
          >
            <CardTemplateButton
              src={require("../assets/img/templates/template_1.png")}
              onClick={() => SetLayoutCard(1)}
            />
          </div>
          {/* <div
            id="horizontal"
            className="flex-row g20 is-centered"
            style={horizontal ? { display: "flex" } : { display: "none" }}
          >
            <div className="card-hor">
              <img src={require("../assets/img/ticket-example2.png")} alt="" />
            </div>
            <div className="card-hor">
              <img src={require("../assets/img/ticket-example2.png")} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
  // //EMAIL INPUT FUNCTIONS
  // const EmailChange = (idx) => (evt) => {
  //   const newemails = emails.emails.map((email, sidx) => {
  //     if (idx !== sidx) return email;
  //     return { ...email, email: evt.target.value };
  //   });
  //   setEmails({ emails: newemails });
  // };
  // const AddInputEmail = () => {
  //   setEmails({
  //     emails: emails.emails.concat([{ email: "" }]),
  //   });
  // };
  // const RemoveInputEmail = (idx) => () => {
  //   setEmails({
  //     emails: emails.emails.filter((s, sidx) => idx !== sidx),
  //   });
  // };
  // // PHONE INPUT FUNCTIONS
  // const PhoneChange = (idx) => (evt) => {
  //   const newphones = phones.phones.map((phone, sidx) => {
  //     if (idx !== sidx) return phone;
  //     return { ...phone, phone_number: evt.target.value };
  //   });

  //   setPhones({ phones: newphones });
  // };
  // const AddInputPhone = () => {
  //   setPhones({
  //     phones: phones.phones.concat([{ phone_number: "" }]),
  //   });
  // };
  // const RemoveInputPhone = (idx) => () => {
  //   setPhones({
  //     phones: phones.phones.filter((s, sidx) => idx !== sidx),
  //   });
  // };
  // // ADDRESS INPUT FUNCTIONS
  // const AddressChange = (idx) => (evt) => {
  //   const newadresses = addresses.addresses.map((address, sidx) => {
  //     if (idx !== sidx) return address;
  //     return { ...address, address: evt.target.value };
  //   });

  //   setAddress({ addresses: newadresses });
  // };
  // const AddInputAddress = () => {
  //   setAddress({
  //     addresses: addresses.addresses.concat([{ address: "" }]),
  //   });
  // };
  // const RemoveInputAddress = (idx) => () => {
  //   setAddress({
  //     addresses: addresses.addresses.filter((s, sidx) => idx !=== sidx),
  //   });
  // };
  // SOCIAL DROPDOWN FUNCTIONS

  const SocialCheck = (value, checked) => {
    if (value !== null && checked) {
      let social = [...socials, value];
      switch (value) {
        case "Facebook":
          setSocial(social);
          setFacebook(social);
          break;
        case "Instagram":
          setSocial(social);
          setInstagram(social);
          break;
        case "Linkedin":
          setSocial(social);
          setLinkedin(social);
          break;
        case "Skype":
          setSocial(social);
          setSkype(social);
          break;
        case "Youtube":
          setSocial(social);
          setYoutube(social);
          break;
        case "Whatsapp":
          setSocial(social);
          setWhatsapp(social);
          break;

        default:
          break;
      }
    }
    if (value !== null && !checked) {
      let tempSocial = socials.filter((social) => social !== value);
      setSocial(tempSocial);
      switch (value) {
        case "Facebook":
          setFacebook("");
          setFacebookURL("");
          break;
        case "Instagram":
          setInstagram("");
          setInstagramURL("");
          break;
        case "Linkedin":
          setLinkedin("");
          setLinkedinURL("");
          break;
        case "Skype":
          setSkype("");
          setSkype("");
          break;
        case "Youtube":
          setYoutube("");
          setYoutubeURL("");
          break;
        case "Whatsapp":
          setWhatsapp("");
          setWhatsappURL("");
          break;
        default:
          break;
      }
    }
  };
  const UpdateSocial = (value) => {
    switch (value) {
      case "Facebook":
        return facebookURL;
      case "Instagram":
        return instagramURL;
      case "Linkedin":
        return linkedinURL;
      case "Skype":
        return skypeURL;
      case "Youtube":
        return youtubeURL;
      case "Whatsapp":
        return whatsappURL;
      default:
        break;
    }
  };
  function SetSocialURL(value, socialvalue) {
    let result = {
      social: socialvalue,
      socials: [{ social_url: value }],
    };
    switch (socialvalue) {
      case "Facebook":
        setFacebookURL(value);
        setSocialArray({
          ...socialArray,
          social: socialvalue,
          socials: [{ social_url: value }],
        });
        break;
      case "Instagram":
        setInstagramURL(value);
        setSocialArray({
          ...socialArray,
          social: socialvalue,
          socials: [{ social_url: value }],
        });
        break;
      case "Linkedin":
        setLinkedinURL(value);
        setSocialArray(result);
        break;
      case "Skype":
        setSkypeURL(value);
        setSocialArray(result);
        break;
      case "Youtube":
        setYoutubeURL(value);
        setSocialArray(result);
        break;
      case "Whatsapp":
        setWhatsappURL(value);
        setSocialArray(result);
        break;

      default:
        break;
    }
  }
  const ToggleDropDown = () => {
    setDropdown(!social_dropdown);
  };
  // function SaveSocial() {
  //   setSocialArray(["Facebook"], [facebookURL]);
  //   setSocialArray(["Instagram"], [instagramURL]);
  //   setSocialArray(["Linkedin"], [linkedinURL]);
  // }
  return (
    <main className="h-100 w-100 flex-row">
      <form className="grid-editor flex-grow" onSubmit={SendtoApi}>
        <header className="side-nav">
          <nav className="h-100 flex-column flex-align-center gap-20">
            <img src={LogoIcon} alt="" />
            <div
              onClick={() => {
                setScreen(true);
                setScreen3(false);
                setScreen4(false);
                setScreen2(false);
                setScreen5(false);
              }}
              className={`side-nav-link ${screen ? "active" : ""}`}
            >
              <ViewCompactIcon />
              Modelos
            </div>
            <div
              onClick={() => {
                setScreen(false);
                setScreen3(false);
                setScreen4(false);
                setScreen2(true);
                setScreen5(false);
              }}
              className={`side-nav-link  ${screen2 ? "active" : ""}`}
            >
              <ColorLens />
              Cores
            </div>
            <div
              onClick={() => {
                setScreen(false);
                setScreen2(false);
                setScreen4(false);
                setScreen3(true);
                setScreen5(false);
              }}
              className={`side-nav-link  ${screen3 ? "active" : ""}`}
            >
              <CorporateFareIcon />
              Informação
            </div>
            <div
              onClick={() => {
                setScreen(false);
                setScreen2(false);
                setScreen3(false);
                setScreen4(true);
                setScreen5(false);
              }}
              className={`side-nav-link  ${screen4 ? "active" : ""}`}
            >
              <Help />
              Informação Add.
            </div>
            <div
              onClick={() => {
                setScreen(false);
                setScreen2(false);
                setScreen3(false);
                setScreen4(false);
                setScreen5(true);
              }}
              className={`side-nav-link  ${screen5 ? "active" : ""}`}
            >
              <Group />
              Social
            </div>
            <div className="m-t-auto ">
              <div className="flex-column flex-align-center gap-20">
                <Buttons
                  buttonStyle={"secondary"}
                  buttonSize={"full-width"}
                  type={"button"}
                  value={"Cancelar"}
                  onClick={() => navigate("/dashboard/")}
                ></Buttons>
                <Buttons
                  value={"Guardar"}
                  buttonSize={"full-width"}
                  type="submit"
                />
              </div>
            </div>
          </nav>
        </header>
        <div className="grid-2-3 overflow-hidden">
          <div className="position-relative p0-20 border-box overflow-hidden flex-column flex-justify-center flex-align-center">
            <div className="bg_lights">
              <Lines />
            </div>
            <div
              className="flex-column gap-20 h-100 flex-align-center"
              style={{ height: "90%" }}
            >
              <div
                className="box-default h-100 text-centered p-20"
                style={
                  layoutTemplate !== null ? { opacity: "1" } : { opacity: "0" }
                }
              >
                {layoutTemplate === 1 ? (
                  <CardDefault
                    herocolor={herocolor}
                    textcolor={textcolor}
                    titlecolor={titlecolor}
                    iconcolor={iconcolor}
                    logoimage={logoimage}
                    firstname={firstname}
                    lastname={lastname}
                    cargo={cargo}
                    emails={{ email_business, email_personal }}
                    phones={{
                      phone_business,
                      phone_personal,
                      phone_alternative,
                    }}
                    addresses={{ address_hq, address }}
                    website={website}
                    instagram={instagramURL}
                    facebook={facebookURL}
                    linkedin={linkedinURL}
                    whatsapp={whatsapp}
                    youtube={youtubeURL}
                    skype={skype}
                  />
                ) : null}
              </div>
            </div>
            <small style={{ position: "absolute", bottom: "10px" }}>
              © {moment().format('YYYY')} Vipa Infinity. Todos os direitos reservados.
            </small>
          </div>
          <div className="box-default">
            {screen ? (
              <ThemeSettings />
            ) : screen2 ? (
              <>
                <div className="content-sidebar text-left p-20 gap-20">
                  <h3>Cores</h3>
                  <p>Fundo</p>
                  <div className="flex-row gap-10">
                    <ColorInput
                      id="hero_color"
                      type={"color"}
                      custom={true}
                      value={herocolor !== "" ? herocolor : "#000000"}
                      onChange={(event) => setHeroColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#977BFF"}
                      onClick={(event) => setHeroColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#8C0038"}
                      onClick={(event) => setHeroColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#D63A76"}
                      onClick={(event) => setHeroColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#439CD4"}
                      onClick={(event) => setHeroColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#135DA4"}
                      onClick={(event) => setHeroColor(event.target.value)}
                    />
                  </div>
                  <p>Título</p>
                  <div className="flex-row gap-10">
                    <ColorInput
                      id="title_color"
                      type={"color"}
                      custom={true}
                      value={titlecolor !== "" ? titlecolor : "#000000"}
                      onChange={(event) => setTitleColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#977BFF"}
                      onClick={(event) => setTitleColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#8C0038"}
                      onClick={(event) => setTitleColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#D63A76"}
                      onClick={(event) => setTitleColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#439CD4"}
                      onClick={(event) => setTitleColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#135DA4"}
                      onClick={(event) => setTitleColor(event.target.value)}
                    />
                  </div>
                  <p>Texto</p>
                  <div className="flex-row gap-10">
                    <ColorInput
                      id="text_color"
                      type={"color"}
                      custom={true}
                      value={textcolor !== "" ? textcolor : "#000000"}
                      onChange={(event) => setTextColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#977BFF"}
                      onClick={(event) => setTextColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#8C0038"}
                      onClick={(event) => setTextColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#D63A76"}
                      onClick={(event) => setTextColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#439CD4"}
                      onClick={(event) => setTextColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#135DA4"}
                      onClick={(event) => setTextColor(event.target.value)}
                    />
                  </div>
                  <p>Icons</p>
                  <div className="flex-row gap-10">
                    <ColorInput
                      id="icon_color"
                      type={"color"}
                      custom={true}
                      value={iconcolor !== "" ? iconcolor : "#000000"}
                      onChange={(event) => setIconColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#977BFF"}
                      onClick={(event) => setIconColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#8C0038"}
                      onClick={(event) => setIconColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#D63A76"}
                      onClick={(event) => setIconColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#439CD4"}
                      onClick={(event) => setIconColor(event.target.value)}
                    />
                    <ColorInput
                      id="hero_color"
                      type={"button"}
                      value={"#135DA4"}
                      onClick={(event) => setIconColor(event.target.value)}
                    />
                  </div>
                  {/* <h5>Banco de Imagens</h5>
                  <div className="container-items">
                    <div className="image-bank gap-20">
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                      <img src={require("../assets/img/Rectangle 5.png")} />
                      <img src={require("../assets/img/Rectangle 6.png")} />
                    </div>
                  </div> */}
                </div>
              </>
            ) : screen3 ? (
              <>
                <div className="content-sidebar text-left p-20 gap-40">
                  <h3>Informação</h3>
                  <div>
                    <h5>Logotipo</h5>
                    <FileInput
                      value={logoimage}
                      onChange={(e) => setLogo(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>Primeiro Nome</p>
                    <TextInput
                      id="firstName"
                      type="text"
                      value={firstname}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Ultimo Nome</p>
                    <TextInput
                      id="lastName"
                      type="text"
                      value={lastname}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Cargo</p>
                    <TextInput
                      id="cargo"
                      type="text"
                      value={cargo}
                      onChange={(event) => setCargo(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Email Empresarial</p>
                    <TextInput
                      type={"text"}
                      value={email_business}
                      onChange={(event) => setEmailBusiness(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Email Pessoal</p>
                    <TextInput
                      type={"text"}
                      value={email_personal}
                      onChange={(event) => setEmailPersonal(event.target.value)}
                    />
                  </div>
                  {/* <div>
                    <p>Email</p>
                    <div className="flex-column gap-20">
                      {emails.emails.map((email, idx) => (
                        <div className="flex-row gap-10 w-100">
                          <TextInput
                            type={"text"}
                            style={{ flexGrow: "1" }}
                            placeholder={`Email #${idx + 1}`}
                            value={email.email}
                            onChange={EmailChange(idx)}
                          />
                          <Buttons
                            type="button"
                            onClick={RemoveInputEmail(idx)}
                            buttonSize="small"
                            buttonStyle={"secondary"}
                            value={<Remove />}
                          />
                        </div>
                      ))}
                      {emails.emails.length >= 3 ? null : (
                        <Buttons
                          type="button"
                          onClick={AddInputEmail}
                          buttonSize="btn-adaptive"
                          value={"Adicionar Email"}
                        />
                      )}
                    </div>
                  </div> */}
                  <div>
                    <p>Telefone Empresarial</p>
                    <TextInput
                      type={"number"}
                      value={phone_business}
                      onChange={(event) => setPhoneBusiness(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Telefone Pessoal</p>
                    <TextInput
                      type={"number"}
                      value={phone_personal}
                      onChange={(event) => setPhonePersonal(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Telefone Alternativo</p>
                    <TextInput
                      type={"number"}
                      value={phone_alternative}
                      onChange={(event) =>
                        setPhoneAlternative(event.target.value)
                      }
                    />
                  </div>
                  {/* <div>
                    <p>Telefone</p>

                     <div className="flex-column gap-20">
                      {phones.phones.map((phone, idx) => (
                        <div className="flex-row  gap-10 w-100">
                          <TextInput
                            type={"number"}
                            placeholder={`Telefone #${idx + 1}`}
                            style={{ flexGrow: "1" }}
                            value={phone.phone_number}
                            onChange={PhoneChange(idx)}
                          />
                          <Buttons
                            type="button"
                            onClick={RemoveInputPhone(idx)}
                            buttonSize="small"
                            buttonStyle={"secondary"}
                            value={<Remove />}
                          />
                        </div>
                      ))}
                      {phones.phones.length >= 3 ? null : (
                        <Buttons
                          type="button"
                          onClick={AddInputPhone}
                          buttonSize="btn-adaptive"
                          value={"Adicionar Telefone"}
                        />
                      )}
                    </div> 
                  </div> */}
                </div>
              </>
            ) : screen4 ? (
              <>
                <div className="content-sidebar text-left p-20 gap-40">
                  <h3>Informação Adicional</h3>
                  <div>
                    <p>Morada da Sede</p>
                    <TextInput
                      type={"text"}
                      value={address_hq}
                      onChange={(event) => setAddressHQ(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Morada</p>
                    <TextInput
                      type={"text"}
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                  {/* <div>
                    <p>Morada</p>
                    <div className="flex-column g20">
                      {addresses.addresses.map((address, idx) => (
                        <div className="flex-row  gap-10 w-100 separation-small">
                          <TextInput
                            type={"text"}
                            placeholder={`Morada #${idx + 1}`}
                            style={{ flexGrow: "1" }}
                            value={address.address}
                            onChange={AddressChange(idx)}
                          />
                          <Buttons
                            type="button"
                            onClick={RemoveInputAddress(idx)}
                            buttonSize="small"
                            buttonStyle={"secondary"}
                            value={<Remove />}
                          />
                        </div>
                      ))}
                      {addresses.addresses.length >= 2 ? null : (
                        <Buttons
                          type="button"
                          onClick={AddInputAddress}
                          buttonSize="full-width"
                          value={"Adicionar Morada"}
                        />
                      )}
                    </div>
                  </div> */}
                  <div>
                    <p>Website</p>
                    <TextInput
                      id={"website"}
                      type={"text"}
                      value={website}
                      onChange={(event) => setWebsite(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Whatsapp</p>
                    <TextInput
                      id={"whatsapp"}
                      type={"number"}
                      value={whatsapp}
                      onChange={(event) => setWhatsapp(event.target.value)}
                    />
                  </div>
                  <div>
                    <p>Skype</p>
                    <TextInput
                      id={"skype"}
                      type={"text"}
                      value={skype}
                      onChange={(event) => setSkype(event.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : screen5 ? (
              <>
                <div className="content-sidebar text-left p-20 gap-20">
                  <h3>Social</h3>
                  <div className="create-dropdown">
                    <div className="flex-row gap-20 flex-align-center">
                      <h4>Redes Social</h4>

                      <Buttons
                        type={"button"}
                        icon={social_dropdown ? <Remove /> : <Add />}
                        onClick={ToggleDropDown}
                      />
                    </div>
                    <ul
                      style={
                        social_dropdown
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <input
                          type="checkbox"
                          value={"Facebook"}
                          checked={facebook !== "" ? true : null}
                          onClick={(e) =>
                            SocialCheck(e.target.value, e.target.checked)
                          }
                        />
                        <div className="checkmark"></div>
                        Facebook
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value={"Instagram"}
                          checked={instagram !== "" ? true : null}
                          onClick={(e) =>
                            SocialCheck(e.target.value, e.target.checked)
                          }
                        />
                        <div className="checkmark"></div>
                        Instagram
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value={"Linkedin"}
                          checked={linkedin !== "" ? true : null}
                          onClick={(e) =>
                            SocialCheck(e.target.value, e.target.checked)
                          }
                        />
                        <div className="checkmark"></div>
                        Linkedin
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          value={"Youtube"}
                          checked={youtube !== "" ? true : null}
                          onChange={(e) =>
                            SocialCheck(e.target.value, e.target.checked)
                          }
                        />
                        <div className="checkmark"></div>
                        Youtube
                      </li>
                    </ul>
                  </div>
                  <div className="flex-column gap-20 flex-align-stretch">
                    {socials.map((social) => {
                      return (
                        <div>
                          <p>{social}</p>
                          <TextInput
                            type={"text"}
                            value={UpdateSocial(social)}
                            onChange={(e) =>
                              SetSocialURL(e.target.value, social)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </form>
    </main>
  );
};

export default CreateCard;
