import {
  Business,
  Contacts,
  Language,
  LocationOn,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import React from "react";
import styled from "styled-components";
import Facebook from "../img/social/Facebook_logo.svg";
import Instagram from "../img/social/insta_logo.png";
import Linkedin from "../img/social/linkedin_logo.png";
import { ReactComponent as SkypeIcon } from "../img/social/skype_icon.svg";
import Youtube from "../img/social/youtube_logo.png";

let primaryColor = "#977BFF";
// let secondaryColor = "#000";
let whiteColor = "#fff";

const CardTemplate = styled.div`
  width: 450px;
  background-color: var(--white);
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
const CardHero = styled.div`
  width: 100%;
  padding: 1.25rem 0;
  height: clamp(230px, 330px, 370px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s ease-in;
  ::before {
    content: "";
    bottom: 0;
    right: -10%;
    left: -5%;
    height: 120px;
    background: ${(props) => props.theme.bg};
    filter: ${(props) => props.theme.main};
    position: absolute;
    -webkit-clip-path: ellipse(50% 80% at 50% 100%);
    clip-path: ellipse(50% 70% at 50% 100%);
  }
  ::after {
    content: "";
    bottom: 0;
    right: -5%;
    left: -5%;
    height: 120px;
    background: ${whiteColor};
    position: absolute;
    -webkit-clip-path: ellipse(50% 60% at 50% 90%);
    clip-path: ellipse(50% 50% at 50% 90%);
  }
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  text-align: left;
`;
const CardButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  padding: 1.25rem 0;
`;
const CardButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
`;
const CardItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding: 1.25rem 0.625rem;
  div p {
    font-weight: 500;
    overflow-wrap: break-word;
    word-break: break-word;

    :nth-child(2) {
      opacity: 0.7;
    }
  }
`;
const CardSocialWrap = styled.div`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 1.25rem 0;
  margin-top: auto;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const SocialIcon = styled.div`
  width: 50px;
  height: 50px;
  flex-shrink: 1;
  img {
    width: 100%;
    height: 100%;
  }
`;
function Card_Default({
  herocolor,
  textcolor,
  titlecolor,
  iconcolor,
  logoimage,
  firstname,
  lastname,
  cargo,
  emails,
  phones,
  addresses,
  website,
  facebook,
  linkedin,
  whatsapp,
  youtube,
  instagram,
  skype,
}) {
  const theme = {
    main: herocolor ? "brightness(150%)" : "unset",
    bg: herocolor ? herocolor : primaryColor,
  };
  return (
    <CardTemplate style={textcolor ? { color: textcolor } : { color: "black" }}>
      <CardHero
        theme={theme}
        style={
          herocolor
            ? { backgroundColor: herocolor }
            : {
                background:
                  "linear-gradient(180deg, #0F0C0F 0%, #3F2760 27.89%)",
              }
        }
      >
        <h4 style={titlecolor ? { color: titlecolor } : { color: whiteColor }}>
          {firstname} {lastname}
        </h4>
        <p style={titlecolor ? { color: titlecolor } : { color: whiteColor }}>
          {cargo}
        </p>
        <div style={{ backgroundColor: whiteColor }}></div>
      </CardHero>
      <CardContent>
        <CardButtonWrap>
          <CardButton
            className="flex-row flex-align-center p-10-20 gap-10"
            style={
              herocolor || titlecolor
                ? { backgroundColor: herocolor, color: titlecolor }
                : { backgroundColor: primaryColor, color: whiteColor }
            }
          >
            <Contacts />
            <p>Adicionar como novo contacto</p>
          </CardButton>
        </CardButtonWrap>

        <div>
          <CardItem
            style={
              emails.email_business !== "" && emails.email_business !== null
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <AlternateEmailIcon
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{emails.email_business}</p>
              <p>Email Empresarial</p>
            </div>
          </CardItem>
        </div>
        <div>
          <CardItem
            style={
              emails.email_personal !== "" && emails.email_personal !== null
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <AlternateEmailIcon
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{emails.email_personal}</p>
              <p>Email Pessoal</p>
            </div>
          </CardItem>
        </div>

        <div>
          <CardItem
            style={
              phones.phone_business !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Phone
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{phones.phone_business}</p>
              <p>Telefone Empresarial</p>
            </div>
          </CardItem>
          <CardItem
            style={
              phones.phone_personal !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Phone
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{phones.phone_personal}</p>
              <p>Telefone Pessoal</p>
            </div>
          </CardItem>
          <CardItem
            style={
              phones.phone_alternative !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Phone
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{phones.phone_alternative}</p>
              <p>Telefone Alternativo</p>
            </div>
          </CardItem>
        </div>
        <CardItem
          style={website !== "" ? { display: "flex" } : { display: "none" }}
        >
          <Language
            style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
          />
          <div>
            <p>{website}</p>
            <p>Website</p>
          </div>
        </CardItem>
        <div>
          <CardItem
            style={
              addresses.address_hq !== null && addresses.address_hq !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Business
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{addresses.address_hq}</p>
              <p>Morada da Sede</p>
            </div>
          </CardItem>
        </div>
        <div>
          <CardItem
            style={
              addresses.address !== null && addresses.address !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <LocationOn
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{addresses.address}</p>
              <p>Morada</p>
            </div>
          </CardItem>
        </div>
        <div>
          <CardItem
            style={
              whatsapp !== null && whatsapp !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <WhatsApp
              style={iconcolor ? { color: iconcolor } : { color: primaryColor }}
            />
            <div>
              <p>{whatsapp}</p>
              <p>Whatsapp</p>
            </div>
          </CardItem>
        </div>
        <div>
          <CardItem
            style={
              skype !== null && skype !== ""
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <SkypeIcon
              style={iconcolor ? { fill: iconcolor } : { fill: primaryColor }}
            />
            <div>
              <p>{skype}</p>
              <p>Skype</p>
            </div>
          </CardItem>
        </div>
        <CardSocialWrap>
          {/* <h4
            className="separation-small"
            style={
              instagram || facebook || linkedin !== ""
                ? { display: "block" }
                : { display: "none" }
            }
          >
            Redes Sociais
          </h4> */}
          <Row>
            <SocialIcon
              style={
                instagram !== "" ? { display: "block" } : { display: "none" }
              }
            >
              <a href="https://instagram.com">
                <img src={Instagram} alt="" />
              </a>
            </SocialIcon>
            <SocialIcon
              style={
                facebook !== "" ? { display: "block" } : { display: "none" }
              }
            >
              <a href="https://facebook.com">
                <img src={Facebook} alt="" />
              </a>
            </SocialIcon>
            <SocialIcon
              style={
                linkedin !== "" ? { display: "block" } : { display: "none" }
              }
            >
              <a href="https://linkedin.com">
                <img src={Linkedin} alt="" />
              </a>
            </SocialIcon>
            <SocialIcon
              style={
                youtube !== "" ? { display: "block" } : { display: "none" }
              }
            >
              <a href="https://youtube.com">
                <img src={Youtube} alt="" />
              </a>
            </SocialIcon>
          </Row>
        </CardSocialWrap>
        <div
          style={
            herocolor
              ? { backgroundColor: herocolor, height: "10px" }
              : { backgroundColor: primaryColor, height: "10px" }
          }
        ></div>
      </CardContent>
    </CardTemplate>
  );
}

export default Card_Default;
