import React, { useEffect, useState } from "react";

import "../css/main.css";

import SideBar from "../assets/components/sidebar";
import styled from "styled-components";

import { AccountCircle, Add } from "@mui/icons-material";
import { Buttons } from "../assets/components/buttons";
import axiosConfig from "../providers/axiosConfig";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Lines } from "../assets/img/bg_lines.svg";
import { url } from "../providers/apiURL";
let primaryColor;
const Card = styled.div`
  box-sizing: border-box;
  width: 350px;
  padding: 20px;
  border-radius: 15px;
  background-color: ${primaryColor};
  min-height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
function DashBoard() {
  const [client_name, setName] = useState("");
  const [client_cards, setCards] = useState([]);
  const navigate = useNavigate();

  async function GetUser() {
    try {
      await axiosConfig.get("/me").then((res) => {
        setName(res.data.first_name + " " + res.data.last_name);
        axiosConfig.get("/me/dashboard/" + res.data.id).then((res) => {
          setCards(res.data.cards);
        });
      });
    } catch {
      axiosConfig.get("/me").then((res) => {
        window.location.reload();
        setName(res.data.first_name + " " + res.data.last_name);
        axiosConfig.get("/me/dashboard/" + res.data.id).then((res) => {
          setCards(res.data.cards);
        });
      });
    }
  }
  useEffect(() => {
    GetUser();
  }, []);

  function OpenCard(uuid) {
    window.open(url + "#/preview/" + uuid);
  }
  return (
    <main className="h-100 flex-row">
      <SideBar />
      <div className="flex-column w-100 p-40">
        <div className="bg_lights">
          <Lines />
        </div>
        <div className="flex-row gap-20 w-100">
          <div className="icon-round editable">
            <AccountCircle />
          </div>
          <div>
            <h1>Olá, {client_name}</h1>
            <p>Bem-vindo ao Vipa Infinity</p>
          </div>
        </div>
        <div className="flex-row w-100 flex-justify-right gap-20">
          <Buttons
            icon={<Add />}
            type={"button"}
            value={"Criar cartão"}
            buttonStyle={"secondary"}
            buttonSize={"big"}
            onClick={() => navigate("/dashboard/createcard")}
          />
          {/* <Buttons
        icon={<Upgrade />}
        type={"button"}
        value={"Atualizar Plano"}
        buttonStyle={"secondary"}
        buttonSize={"big"}
        onClick={() => navigate("/dashboard/plan")}
      /> */}
        </div>
        <div className="flex-row">
          <div className="flex-column">
            <div>
              <h3 className="separation-small">Cartões Associados</h3>
              <div className="flex-row gap-20 flex-wrap">
                {client_cards.map((card) => {
                  return (
                    <Card onClick={() => OpenCard(card.uuid)}>
                      <h3>{card.first_name}</h3>
                      <h5>{card.post}</h5>
                    </Card>
                  );
                })}
              </div>
              {/* <div className="box-default p-20">
            <div className="table-container-default">
              <Table>
                <TableRow>
                  <TableItem>
                    <div className="icon-round small">
                      <img src={require("../assets/img/person.jpg")} />
                    </div>
                  </TableItem>
                  <TableItem>João Costa</TableItem>
                  <TableItem>Padaria e Pastelaria</TableItem>
                  <TableItem>23</TableItem>
                  <TableItem>checkbox</TableItem>
                  <TableItem>
                    <div style={{ cursor: "pointer" }}>
                      <span>Editar</span>
                    </div>
                  </TableItem>
                  <TableItem>
                    <Delete />
                  </TableItem>
                </TableRow>
                <TableRow>
                  <TableItem>
                    <div className="icon-round small">
                      <img src={require("../assets/img/person.jpg")} />
                    </div>
                  </TableItem>
                  <TableItem>João Costa</TableItem>
                  <TableItem>Padaria e Pastelaria</TableItem>
                  <TableItem>23</TableItem>
                  <TableItem>checkbox</TableItem>
                  <TableItem>
                    <div style={{ cursor: "pointer" }}>
                      <span>Editar</span>
                    </div>
                  </TableItem>
                  <TableItem>
                    <Delete />
                  </TableItem>
                </TableRow>
              </Table>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashBoard;
// const [addworker, setAddWorker] = useState(false);
// function ToggleAddWorker() {
//   if (selected_company != null) {
//     setAddWorker(!addworker);
//   }
//   return;
// }
// function ToggleEditWorker(id) {
//   if (selected_company != null) {
//     navigate("/dashboard/editworker", {
//       state: { selected_company: selected_company, worker_id: id },
//     });
//   }
//   return;
// }
// function ToggleEditBusiness() {
//   if (selected_company != null) {
//     navigate("/dashboard/editbusiness", {
//       state: { selected_company: selected_company },
//     });
//   }
//   return;
// }
// const [companies, setCompanies] = useState([]);
// const [workers, setWorkers] = useState([]);
// const [selected_company, setSelectedCompany] = useState(0);
// const [worker_id, setWorkerId] = useState();
// const [company_name, setCompanyName] = useState("");
// const [company_area, setCompanyArea] = useState("");
// const [company_address, setCompanyAddress] = useState("");
// const [company_email, setCompanyEmail] = useState("");
// const [company_size, setCompanySize] = useState("");
// const navigate = useNavigate();
// useEffect(() => {
//   if (window.localStorage.getItem("token") == null) {
//     navigate("/");
//   }
//   (async () => {
//     await axios.get(apiUrl + "/me", config).then((res) => {
//       client_id = res.data.id;
//       setName(res.data.first_name + " " + res.data.last_name);
//       axios.get(apiUrl + "/me/dashboard/" + client_id, config).then((res) => {
//         setCompanies(res.data.companies);
//         setWorkers(res.data.workers);
//         console.log(res.data.workers);
//       });
//     });
//   })();
// }, []);
// useEffect(() => {
//   for (let i = 0; i < companies.length; i++) {
//     if (selected_company == companies[i].id) {
//       setCompanyName(companies[i].name);
//       setCompanyAddress(companies[i].address);
//       setCompanyEmail(companies[i].email);
//       setCompanyArea(companies[i].area);
//       setCompanySize(companies[i].size);
//       break;
//     }
//   }
// }, [selected_company]);
// function SelectedCompany(id_company) {
//   setSelectedCompany(id_company);
// }
// function CreateBusiness() {
//   navigate("./createbusiness");
// }
/* {!addworker ? (
        <div className="flex-column is-nowrap is-fullwidth is-fullheight w-1380 m-center pos-rel z1">
          <div className="text-separation-small logo">
            <img alt="" src={vipalogo} />
          </div>
          <section className="is-fullwidth grid-2 g20 text-separation-small">
            <div className="box-default p20">
              <div className="flex-row g10 item-stretch">
                {companies.length > 0 &&
                  companies.map((company) => {
                    return (
                      <div
                        className={
                          selected_company == company.id
                            ? "dash-card active"
                            : "dash-card"
                        }
                        onClick={() => SelectedCompany(company.id)}
                      >
                        <img src={require("../assets/img/business_card.png")} />
                      </div>
                    );
                  })}
                <div className="dash-card empty" onClick={CreateBusiness}>
                  <Add />
                </div>
              </div>
            </div>
            <div className="flex-column is-fullwidth item-stretch g20">
              <div className="box-default p20">
                <div className="flex-row is-nowrap is-between item-flexstart">
                  <div className="flex-column item-stretch g10">
                    <div className="flex-row g10">
                      <div className="round-img medium">
                        <img src={require("../assets/img/person.jpg")} />
                      </div>
                      <div>
                        <h4>{company_name}</h4>
                        <p>{company_area}</p>
                      </div>
                    </div>
                    <div>
                      <p>Morada</p>
                      <p>
                        <b>{company_address}</b>
                      </p>
                    </div>
                    <div>
                      <p>Email</p>
                      <p>
                        <b>{company_email}</b>
                      </p>
                    </div>
                    <div>
                      <p>Tamanho</p>
                      <p>
                        <b>{company_size}</b>
                      </p>
                    </div>
                  </div>
                  <div
                    className="icon-square-default clickable"
                    onClick={ToggleEditBusiness}
                  >
                    <span className="icon">
                      <Create />
                    </span>
                  </div>
                </div>
              </div>
              <div className="box-default p20 is-grow">
                <div className="flex-row is-nowrap is-between item-flexstart">
                  <h4>Trabalhadores</h4>
                  <div
                    className="icon-square-default clickable"
                    onClick={ToggleAddWorker}
                  >
                    <span className="icon">
                      <Add />
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    height: "80%",
                    overflow: "overlay",
                    marginTop: "20px",
                  }}
                >
                  <Table
                    tableStyle={"table-default secondary lined"}
                    classname="is-fullwidth"
                  >
                    {workers.map((worker) => {
                      if (worker.company_id == selected_company) {
                        return (
                          <TableRow>
                            <TableItem>
                              <div className="round-img small">
                                <img
                                  src={require("../assets/img/person.jpg")}
                                />
                              </div>
                            </TableItem>
                            <TableItem>
                              {worker.first_name + " " + worker.last_name}
                            </TableItem>
                            <TableItem>
                              <CreditCard />
                            </TableItem>
                            <TableItem>
                              <Create
                                onClick={() => ToggleEditWorker(worker.id)}
                              />
                            </TableItem>
                            <TableItem>
                              <Search
                                onClick={() =>
                                  window.open(
                                    url +
                                      "/preview/" +
                                      "I1ELsjQpiJIIDnG0PLETmcG1e9NhprGoYdTfEqXa"
                                  )
                                }
                              />
                            </TableItem>
                          </TableRow>
                        );
                      }
                    })}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="flex-column is-nowrap is-fullwidth is-fullheight w-1380 m-center pos-rel z1 is-centered">
          <AddWorker _company_id={selected_company} />
        </div>
      )} */
