import React, { useEffect, useState } from "react";
import SideBar from "../components/sidebar";
// import UserMenu from "../components/usermenu";
// import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
// import PersonIcon from "@mui/icons-material/Person";
// import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
// import { Table, TableItem, TableRow } from "./table";
import { Add, Edit } from "@mui/icons-material";
import { Buttons } from "./buttons";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";

function BusinessView() {
  const [companies, setCompanies] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [selected_company, setSelectedCompany] = useState(0);
  // const [worker_id, setWorkerId] = useState();
  // const [company_name, setCompanyName] = useState("");
  // const [company_area, setCompanyArea] = useState("");
  // const [company_address, setCompanyAddress] = useState("");
  // const [company_email, setCompanyEmail] = useState("");
  // const [company_size, setCompanySize] = useState("");

  // let client_id;
  // const [client_name, setName] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (window.localStorage.getItem("token") === null) {
      navigate("/");
    }
    (async () => {
      await axiosConfig.get("/me").then((res) => {
        // client_id = res.data.id;
        // setName(res.data.first_name + " " + res.data.last_name);
        axiosConfig.get("/me/dashboard/" + res.data.id).then((res) => {
          setCompanies(res.data.companies);
          setWorkers(res.data.workers);
        });
      });
    })();
  }, [navigate]);
  useEffect(() => {
    for (let i = 0; i < companies.length; i++) {
      if (selected_company === companies[i].id) {
        // setCompanyName(companies[i].name);
        // setCompanyAddress(companies[i].address);
        // setCompanyEmail(companies[i].email);
        // setCompanyArea(companies[i].area);
        // setCompanySize(companies[i].size);
        break;
      }
    }
  }, [selected_company, companies]);
  function SelectedCompany(id_company) {
    setSelectedCompany(id_company);
  }
  return (
    <main className="h-100 flex-row">
      <SideBar />
      <div className="flex-column w-100 h-100 p-20 border-box">
        <div className="flex-row gap-20 w-100">
          {companies.length === 0 ? (
            <></>
          ) : (
            <>
              <div className="icon-round editable">
                <img src={require("../img/person.jpg")} alt="" />
              </div>
              <div>
                <h1>Olá, </h1>
                <p>Bem-vindo ao Vipa Infinity</p>
              </div>
            </>
          )}
        </div>
        <div className="flex-row w-100 h-100 overflow-hidden">
          {companies.length === 0 ? (
            <div className="m-auto flex-column flex-align-center gap-40 align-self">
              <div className="img-wrap w-50">
                <img src={require("../img/business/no_card.png")} alt="" />
              </div>
              <h1>Não possuí empresas registadas de momento</h1>
              <Buttons
                icon={<Add />}
                type={"button"}
                value={"Subcrever"}
                buttonSize={"big"}
                onClick={() => navigate("/dashboard/plan/")}
              />
            </div>
          ) : (
            <div className="flex-column gap-20 w-100">
              <div className="flex-row h-50">
                <div className="flex-column">
                  <h2>Empresas</h2>
                  <div className="flex-column gap-20 h-100 overflow-y p0-20 border-box">
                    {companies.length > 0 &&
                      companies.map((company) => {
                        return (
                          <div
                            className={`box-default business-card flex-row flex-align-center p0-20 ${
                              selected_company === company.id ? "active" : " "
                            }`}
                            onClick={() => SelectedCompany(company.id)}
                          >
                            <div className="icon-round">
                              <img src="" alt="" />
                            </div>
                            <p>
                              <b>{company.name}</b>
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="m-t-auto">
                <h2 className="separation-small">Trabalhadores</h2>
                <div
                  className="flex-row gap-20 overflow-y flex-wrap"
                  style={{ height: "240px" }}
                >
                  {workers.map((worker) => {
                    if (worker.company_id === selected_company) {
                      return (
                        <div className="box-default worker-card flex-column flex-align-center p0-20">
                          <div className="icon-round">
                            <img src={require("../img/person.jpg")} alt="" />
                          </div>
                          <p>
                            <b>{worker.first_name + " " + worker.last_name}</b>
                          </p>
                          <p>{worker.post}</p>
                          <div className="controls">
                            <div className="control">
                              <Edit />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default BusinessView;
// <main className="ovr-hd-fullheight">
//   <div className="flex-row is-nowrap is-fullwidth is-fullheight item-stretch">
//     <SideBar Collapsed={false} />
//     <section className="is-fullwidth p0-20 over-hide">
//       <UserMenu />
//       <div className="flex-row is-nowrap g20 item-stretch is-centered ">
//         <div className="flex-column item-stretch g20 ">
//           <div className="box-default flex-row is-nowrap ovr-hd">
//             <div className="business-banner">
//               <img src={require("../img/vipa-connect-banner.png")} alt="" />
//             </div>
//             <div className="flex-row g20 p0-20">
//               <div className="flex-column item-flexstart">
//                 <h4>Vipa Connect</h4>
//                 <p>Corperativo</p>
//               </div>
//               imagens
//             </div>
//           </div>
//           <div className="box-default flex-row is-around g20 p20">
//             <Table tableStyle={"table-default secondary lined"}>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem>Icon</TableItem>
//                 <TableItem>Vipa Connect</TableItem>
//                 <TableItem>Add Carteira</TableItem>
//                 <TableItem>Editar</TableItem>
//                 <TableItem>
//                   <Search />
//                 </TableItem>
//               </TableRow>
//               <TableRow>
//                 <TableItem colspan="5">
//                   <Buttons
//                     buttonStyle={"btn-secondary"}
//                     buttonSize={"btn-adaptive"}
//                     value={"+ Adicionar Trabalhador"}
//                   />
//                 </TableItem>
//               </TableRow>
//             </Table>
//           </div>
//         </div>
//         <div className="box-default p10-20 w-30">
//           <div className="flex-row is-centered text-separation">
//             <h5>Coneções</h5>
//           </div>
//           <div className="flex-column  item-stretch">
//             Grafico
//             <div className="flex-row is-nowrap is-between is-fullwidth bb2-white p20-0">
//               <div className="flex-row g20">
//                 <div className="round-img small">
//                   <img alt="" src={require("../img/person.jpg")} />
//                 </div>
//                 <p>Vipa Connect</p>
//               </div>
//               75
//             </div>
//             <div className="flex-row is-nowrap is-between is-fullwidth bb2-white p20-0">
//               <div className="flex-row g20">
//                 <div className="round-img small">
//                   <img alt="" src={require("../img/person.jpg")} />
//                 </div>
//                 <p>Vipa Connect</p>
//               </div>
//               75
//             </div>
//             <div className="flex-row is-nowrap is-between is-fullwidth bb2-white p20-0">
//               <div className="flex-row g20">
//                 <div className="round-img small">
//                   <img alt="" src={require("../img/person.jpg")} />
//                 </div>
//                 <p>Vipa Connect</p>
//               </div>
//               75
//             </div>
//             <div className="flex-row is-nowrap is-between is-fullwidth bb2-white p20-0">
//               <div className="flex-row g20">
//                 <div className="round-img small">
//                   <img alt="" src={require("../img/person.jpg")} />
//                 </div>
//                 <p>Vipa Connect</p>
//               </div>
//               75
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   </div>
// </main>
// <main className="ovr-hd-fullheight">
//         <div className='flex-row is-nowrap is-fullwidth is-fullheight item-stretch'>
//             <SideBar Collapsed={false}/>
//             <section className='is-fullwidth p0-20 over-hide'>
//                 <UserMenu/>
//                 <div className="flex-row g20 item-stretch is-centered">
//                     <div className="flex-column g20 item-stretch">
//                         <div className="box-default flex-row p20">
//                             IMAGEM
//                             <div className="flex-row g20">
//                                 <div className="flex-column item-flexstart">
//                                     <h3>Vipa Connect</h3>
//                                     <p>Corperativo</p>
//                                 </div>
//                                 imagens
//                             </div>
//                         </div>
//                         <div className="box-default p20">
//                             <Table>
//                                 <TableRow>
//                                     <TableItem>Icon</TableItem>
//                                     <TableItem>Vipa Connect</TableItem>
//                                     <TableItem>Add Carteira</TableItem>
//                                     <TableItem>Editar</TableItem>
//                                     <TableItem><Search/></TableItem>
//                                 </TableRow>
//                             </Table>
//                         </div>
//                     </div>
//                     <div className="box-default flex-column item-stretch p10-20">
//                         <h3>Coneções</h3>
//                         <div className="flex-column p20-0">
//                             <div className="flex-row is-nowrap is-between is-fullwidth">
//                                 <div className="flex-row">
//                                     icon
//                                     <p>Vipa Connect</p>
//                                 </div>
//                                 75
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     </main>
