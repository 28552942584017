import React, { useEffect } from "react";
import { useState } from "react";
import useScrollPosition from "@react-hook/window-scroll";
import "./css/main.css";
import { ReactComponent as Lines } from "./assets/img/bg_lines.svg";
import Logo from "./assets/img/vipainfinity-logo.svg";
import Logo_Icon from "./assets/img/logo_icon.svg";
import {
  Brush,
  Facebook,
  Instagram,
  LaptopChromebook,
  LinkedIn,
  Schema,
  Wifi,
} from "@mui/icons-material";
import { Buttons } from "./assets/components/buttons";
import Login from "./routes/login";
// import apiUrl, { config } from "./assets/components/apiURL";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosConfig from "./providers/axiosConfig";

function Home() {
  let navigate = useNavigate();

  const scrollY = useScrollPosition(60);

  const [login, setLogin] = useState(false);
  function toggleLogin() {
    setLogin(!login);
  }
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [phone4, setPhone4] = useState("");
  const [sobre, setSobre] = useState("");
  const [more, setMore] = useState("");

  const data = {
    first_name: firstName,
    last_name: lastName,
    image: image,
  };
  useEffect(() => {
    if (!window.localStorage.getItem("token")) {
      return;
    } else {
      axiosConfig.get("/me").then((res) => {
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setImage(res.data.image);
      });
    }
  }, []);

  useEffect(() => {
    const maxScroll = 1000;
    const normalize = (max, inverse) => {
      if (inverse) {
        return 1 - (scrollY * max) / maxScroll;
      }
      return (scrollY * max) / maxScroll;
    };
    if (scrollY > 5 && scrollY <= maxScroll) {
      setPhone1(`animation: unset; transform: rotateY(${normalize(
        -20,
        0
      )}deg) rotateX(${normalize(55, 0)}deg) translate(${normalize(
        -600,
        0
      )}px, ${normalize(-600, 0)}px) skew(${normalize(35, 0)}deg, ${normalize(
        -10,
        0
      )}deg);
      opacity: ${normalize(1, 1)}`);

      setPhone2(`animation: unset; transform: rotateY(${normalize(
        20,
        0
      )}deg) rotateX(${normalize(55, 0)}deg) translate(${normalize(
        600,
        0
      )}px, ${normalize(-600, 0)}px) skew(${normalize(-35, 0)}deg, ${normalize(
        10,
        0
      )}deg);
      opacity: ${normalize(1, 1)}`);

      document.getElementById("phone_1").setAttribute("style", phone1);
      document.getElementById("phone_2").setAttribute("style", phone2);
    }
    if (scrollY > 700 && scrollY <= 850) {
      setSobre(`opacity: 1`);
      setPhone3(
        `animation: slide-in-fwd-tr 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;`
      );
      document.getElementById("sobre").setAttribute("style", sobre);
      document.getElementById("phone_3").setAttribute("style", phone3);
    } else if (scrollY > 1200) {
      document.getElementById("sobre").setAttribute("style", "opacity:1");
    }
    if (scrollY > 1500 && scrollY <= 1750) {
      setMore(`opacity: 1`);
      setPhone4(
        `animation: slide-in-elliptic-bottom-bck 1.5s cubic-bezier(0.50, 0.460, 0.450, 0.940) both;`
      );
      document.getElementById("more").setAttribute("style", more);
      document.getElementById("phone_4").setAttribute("style", phone4);
    } else if (scrollY < 500) {
      document.getElementById("sobre").setAttribute("style", "opacity:0");
      document
        .getElementById("phone_3")
        .setAttribute("style", "animation:unset");
      document.getElementById("more").setAttribute("style", 0);
      document
        .getElementById("phone_4")
        .setAttribute("style", "animation:unset");
    }
  }, [scrollY, more, phone1, phone2, phone3, phone4, sobre]);

  return (
    <main className="w-100 h-100">
      <Login openfunction={toggleLogin} open={login} />

      <header className="w-100">
        <nav className="w-1700 flex-row flex-align-center gap-20 p-10-0">
          <div className="logo">
            <img src={Logo_Icon} alt="" />
          </div>
          <a href="/#" className="active">
            Home
          </a>
          <a href="/#">Sobre</a>
          {/* <a href="/#">Planos</a> */}
          <a href="/#">Contactos</a>
          {data.first_name !== "" ? (
            <div
              className="flex-row flex-align-center gap-20 m-l-auto"
              onClick={() => navigate("/dashboard/")}
            >
              <div className="icon-round">
                <img src={require("./assets/img/person.jpg")} alt="" />
              </div>
              <p>{data.first_name + " " + data.last_name}</p>
            </div>
          ) : (
            <div className="flex-row flex-align-center gap-20 m-l-auto">
              <Buttons value="Iniciar sessão" onClick={toggleLogin} />
            </div>
          )}
        </nav>
      </header>
      <div className="bg_lights">
        <Lines />
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="hero" className=" overflow-hidden">
        <div className="position-relative w-1700 h-100 z-index-1">
          <div className="logo separation">
            <img src={Logo} alt="" />
          </div>
          <div className="flex-column flex-justify-center h-50">
            <div className="flex-row flex-justify-between h-50">
              <div className="caption flex-column flex-justify-center position-relative z-index-1">
                <h1 className="h_large">CREATE & LIVE</h1>
                <h1 className="h_large">
                  YOUR <span>INFINITY</span>
                </h1>
                <h4 className="uppercase white-space-nowrap">
                  Uma variadade de soluções num só sítio{" "}
                </h4>
              </div>
              <div className="hero-image">
                <img
                  id="phone_1"
                  src={require("./assets/img/index/phone_1.png")}
                  alt=""
                />
                <img
                  id="phone_2"
                  src={require("./assets/img/index/phone_2.png")}
                  alt=""
                />
              </div>
              <div className="social flex-column flex-justify-center gap-20">
                <Instagram />
                <Facebook />
                <LinkedIn />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section h-100" id="sobre">
        <h1 className="uppercase">Sobre</h1>
        <div className="flex-row">
          <div className="flex-column flex-justify-around flex-basis-450">
            <div className="flex-row gap-20">
              <div className="icon-wrap">
                <LaptopChromebook />
              </div>
              <div className="flex-column gap-10">
                <h3>Qualquer Momento</h3>
                <p className="text_large">
                  Modifique a qualquer momento e a qualquer hora o seu cartão
                </p>
              </div>
            </div>
            <div className="flex-row gap-20">
              <div className="icon-wrap">
                <Wifi />
              </div>
              <div className="flex-column gap-10">
                <h3>100% Digital</h3>
                <p className="text_large">
                  Edite os seus cartões online, sem a necessidade de instalar
                  qualquer software no seu dispositivo
                </p>
              </div>
            </div>
          </div>

          <div className="flex-grow text-center">
            <img
              id="phone_3"
              src={require("./assets/img/index/phone.png")}
              alt=""
            />
          </div>
          <div className="flex-column flex-justify-around flex-basis-450">
            <div className="flex-row gap-20">
              <div className="icon-wrap">
                <Schema />
              </div>
              <div className="flex-column gap-10">
                <h3>Aumente a sua rede de contactos</h3>
                <p className="text_large">
                  Aumente a sua rede de contactos facilmente
                </p>
              </div>
            </div>
            <div className="flex-row gap-20">
              <div className="icon-wrap">
                <Brush />
              </div>
              <div className="flex-column gap-10">
                <h3>Fácil de utilização</h3>
                <p className="text_large">
                  Não necessita ter qualquer experiência para desenhar o seu
                  cartão
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="more">
        <div className="flex-row flex-align-center">
          <div>
            <h1 className="separation-small">O Vipa Infinity</h1>
            <p className="text_large separation-small">
              O Vipa Infinity enquanto plataforma com um foco na
              desmaterialização e diminuição dos custos associados à impressão
              de cartões de visita e do impacto ambiental causado, é
              desenvolvido tendo como propósito a criação e personalização de
              business cards, disponibilizando ao cliente o serviço de impressão
              em cartões com tecnologia NFC.
            </p>
            <p className="text_large">
              É um serviço/ produto único no mercado por permitir aos seus
              clientes/ subscritores criarem e personalizarem os seus business
              cards, levando à desmaterialização dos processos físicos e
              assegurando uma gestão mais eficiente do seu dia-a-dia e do seu
              negócio. É através do Vipa Infinity que os clientes poderão ter os
              seus business cards, em formato digital e na Wallet.
            </p>
          </div>
          <img
            id="phone_4"
            src={require("./assets/img/index/about_phone.png")}
            alt=""
          />
        </div>
      </section>
      {/* <section className="section h-100">
        <h1>Planos</h1>
        <div className="flex-row flex-justify-center gap-20 separation">
          <div className={organization ? "inactive" : null}>
            <h3>Individual</h3>
          </div>
          <label className="switch">
            <input type="checkbox" onClick={toggleOrganization} />
            <span className="slider"></span>
          </label>
          <div className={organization ? null : "inactive"}>
            <h3>Organização</h3>
          </div>
        </div>
        <div
          id="plan"
          className="flex-row flex-justify-around gap-20"
          style={organization ? { display: "none" } : null}
        >
          <div className="box-default p-40">
            <div className="flex-column gap-10 h-100">
              <h4>Standard</h4>
              <div className="flex-row flex-align-flexend separation-small">
                <h1>20€</h1>
                <h4>/ano</h4>
              </div>
              <ul className="separation-small">
                <li>
                  <CheckCircle />
                  Custom branding
                </li>
                <li>
                  <CheckCircle />
                  Update card designs anytime
                </li>
                <li>
                  <CheckCircle />
                  Up to 20 cards
                </li>
              </ul>
              <div className="m-t-auto">
                <Buttons value="Subcrever" />
              </div>
            </div>
          </div>
          <div className="box-default p-40">
            <div className="flex-column gap-10 h-100">
              <h4>Plus</h4>
              <div className="flex-row flex-align-flexend separation-small">
                <h1>35€</h1>
                <h4>/ano</h4>
              </div>
              <ul className="separation-small">
                <li>
                  <CheckCircle />
                  Custom branding
                </li>
                <li>
                  <CheckCircle />
                  Update card designs anytime
                </li>
                <li>
                  <CheckCircle />
                  Up to 50 cards
                </li>
              </ul>
              <div className="m-t-auto">
                <Buttons value="Subcrever" />
              </div>
            </div>
          </div>
          <div className="box-default p-40">
            <div className="flex-column gap-10 h-100">
              <h4>Premium</h4>
              <div className="flex-row flex-align-flexend separation-small">
                <h1>50€</h1>
                <h4>/ano</h4>
              </div>
              <ul className="separation-small">
                <li>
                  <CheckCircle />
                  Custom branding
                </li>
                <li>
                  <CheckCircle />
                  Update card designs anytime
                </li>
                <li>
                  <CheckCircle />
                  Up to 200 cards
                </li>
              </ul>
              <div className="m-t-auto">
                <Buttons value="Subcrever" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section h-50">
        <div
          className="bg_lights"
          style={{ left: "0", transform: "translate(0, -35%)" }}
        >
          <Lines />
        </div>
        <div className="flex-column flex-align-center gap-20 flex-justify-center h-100">
          <h1>Experimente o nosso serviço</h1>
          <Buttons buttonSize={"big"} value="Começar" />
        </div>
      </section>

      <footer className="overflow-hidden">
        <div className="section p-40 h-50 flex-column">
          <div className="flex-row gap-20 flex-justify-around m-t-auto">
            <img src={Logo} alt="" />
            <div className="flex-column gap-20">
              <h4>Contactos</h4>
              <div className="flex-column gap-10">
                <p>Rua Dr. Fernão de Ornelas nº56, 4º AC</p>
                <p>info@vipaconnect.pt</p>
                <p>(+351) 291 103 685</p>
              </div>
            </div>
            <div className="flex-column gap-20">
              <h4>Serviços</h4>
              <div className="flex-column gap-10">
                <p>Virtual Business Cards</p>
              </div>
            </div>
            <div className="flex-column gap-20">
              <h4>Políticas</h4>
              <div className="flex-column gap-10">
                <p>Termos de serviço</p>
                <p>Política de Privacidade</p>
                <p>Elogios, Sugestões e Reclamações</p>
              </div>
            </div>
          </div>
          <small className="w-100 text-center separation">
            © 2024 Vipa Infinity. Todos os direitos reservados.
          </small>
        </div>
      </footer>
    </main>
  );
}

export default Home;
