import {
  Dashboard,
  Logout,
  Person
} from "@mui/icons-material";
import jQuery from "jquery";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoIcon from "../img/logo_icon.svg";

function SideBar(props) {
  const navigate = useNavigate();
  // const [isBusiness, setBusiness] = useState(false);
  // const toggleBusiness = () => {
  //   if (props.Collapsed === false) {
  //     setBusiness(!isBusiness);
  //   }
  // };
  // const [isEvents, setEvents] = useState(false);
  // const toggleEvents = () => {
  //   if (props.Collapsed === false) {
  //     setEvents(!isEvents);
  //   }
  // };
  function toggleClass(e) {
    let newTarget = jQuery(e.target);
    if (!newTarget.hasClass("side-nav-link")) {
      newTarget = jQuery(e.target).parents(".side-nav-link");
    }
    if (!newTarget.hasClass("active")) {
      jQuery(".side-nav-link.active").removeClass("active");
      newTarget.addClass("active");
    }
  }
  function SignOut() {
    window.localStorage.clear();
    navigate("/");
  }
  return (
    <header className="side-nav">
      <nav className="h-100 flex-column flex-align-center gap-20">
        <img src={LogoIcon} alt="logo" />
        <Link
          to="/dashboard"
          className="side-nav-link active"
          onClick={(e) => toggleClass(e)}
        >
          <Dashboard />
          Dashboard
        </Link>
        <Link
          to="/dashboard/profile"
          className="side-nav-link"
          onClick={(e) => toggleClass(e)}
        >
          <Person />
          <p>Perfil</p>
        </Link>
        {/* <Link
          to="/dashboard/business"
          className="side-nav-link"
          onClick={(e) => toggleClass(e)}
        >
          <Business />
          Empresa
        </Link> */}
        <div className="side-nav-link m-t-auto" onClick={() => SignOut()}>
          <Logout />
          <p>Log Out</p>
        </div>
      </nav>
    </header>
  );
}

export default SideBar;
