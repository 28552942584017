import React from "react";
import { PlanPrice } from "../assets/components/plan_price";
import SideBar from "../assets/components/sidebar";

function Plan() {
  return (
    <main className="h-100 flex-row">
      <SideBar />
      <PlanPrice />
    </main>
  );
}

export default Plan;
