import React from "react";
import SearchIcon from "@mui/icons-material/Search";
// import { style } from "@mui/system";
import { Add, AddAPhoto, AddPhotoAlternate } from "@mui/icons-material";
// import AddIcon from "@mui/icons-material/Add";
// import { Buttons } from "./buttons";

const SIZES = ["input-small", "input-medium", "input-big", "input-adaptive"];

export const SearchBar = () => {
  return (
    <div className="input-secondary">
      <span className="input-icon">
        <SearchIcon />
      </span>
      <input
        type="text"
        id="search"
        className="input-default"
        placeholder="Procurar"
      />
    </div>
  );
};
export const DateBar = () => {
  return <input type="date" id="date" className="input-secondary" />;
};
export const TextInputWithIcon = () => {
  return (
    <div className="input-secondary">
      <span className="input-icon">
        <SearchIcon />
      </span>
      <input type="text" id="text" className="input-default" placeholder="" />
    </div>
  );
};
export const TextInput = ({
  placeholder,
  type,
  inputSize,
  style,
  id,
  value,
  onChange,
  onSubmit,
  icon,
}) => {
  const checkInputSize = SIZES.includes(inputSize) ? inputSize : SIZES[0];
  return (
    <div
      className={`input-wrap ${icon ? null : "no-icon"} ${checkInputSize}`}
      style={style}
    >
      {icon ? <div className="input-icon"> {icon}</div> : null}
      <input
        type={type}
        id={id}
        value={value}
        className="input-inside"
        placeholder={placeholder}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </div>
  );
};
export const ColorInput = ({ id, onChange, onClick, value, type, custom }) => {
  return (
    <div className="input-color" style={{ backgroundColor: value }}>
      <div
        className={"custom"}
        style={custom != null ? { display: "block" } : { display: "none" }}
      >
        <Add />
      </div>
      <input
        type={type}
        id={id}
        onChange={onChange}
        onClick={onClick}
        value={value}
        className="hidden input-adaptive"
      />
    </div>
  );
};
export const FileInput = ({ id, onChange, value }) => {
  return (
    <div className="file-upload logo">
      <AddPhotoAlternate />
      <div className="flex-column gap-10">
        <span>Upload do Logotipo</span>
        <p>{value}</p>
      </div>
      <input type="file" id={id} onChange={onChange} className="upload" />
    </div>
  );
};
export const PhotoInput = ({ id, onChange }) => {
  return (
    <div className="file-upload photo">
      <AddAPhoto />
      <input type="file" id={id} onChange={onChange} className="upload" />
    </div>
  );
};
