import React, { useEffect } from "react";
import { useState } from "react";
// import DoneIcon from "@mui/icons-material/Done";
import { Buttons } from "./buttons";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
// import vipalogo from "../img/vipainfinity-logo.svg";
import { CheckCircle } from "@mui/icons-material";

export function PlanPrice() {
  const [client_id, setClientId] = useState("");
  const [subscription_plan_id, setPlanId] = useState();
  const [organization, setOrganization] = useState(false);
  function toggleOrganization() {
    setOrganization(!organization);
  }
  const data = {
    client_id: client_id,
    subscription_plan_id: subscription_plan_id,
  };
  useEffect(() => {
    axiosConfig.get("/me").then((res) => {
      setClientId(res.data.id);
    });
  }, []);

  const navigate = useNavigate();
  function sendToApi(event) {
    event.preventDefault();
    navigate("/dashboard/payment", { state: { data: data } });
  }
  return (
    <form id="pricing" className="flex-column w-100 p-40" onSubmit={sendToApi}>
      <h1>Planos</h1>
      <div className="flex-row flex-justify-center gap-20 separation">
        <div className={organization ? "inactive" : null}>
          <h3>Individual</h3>
        </div>
        <label className="switch">
          <input type="checkbox" onClick={toggleOrganization} />
          <span className="slider"></span>
        </label>
        <div className={organization ? null : "inactive"}>
          <h3>Organização</h3>
        </div>
      </div>
      <div
        id="plan"
        className="flex-row flex-justify-center gap-20"
        style={organization ? { display: "none" } : null}
      >
        <div className="box-default p-40">
          <div className="flex-column gap-10 h-100">
            <h4>Standard</h4>
            <div className="flex-row flex-align-flexend separation-small">
              <h1>20€</h1>
              <h4>/ano</h4>
            </div>
            <ul className="separation-small">
              <li>
                <CheckCircle />
                Custom branding
              </li>
              <li>
                <CheckCircle />
                Update card designs anytime
              </li>
              <li>
                <CheckCircle />
                Employees can update their details
              </li>
              <li>
                <CheckCircle />
                Up to 20 cards
              </li>
            </ul>
            <div className="m-t-auto">
              <Buttons value="Subcrever" onClick={() => setPlanId(1)} />
            </div>
          </div>
        </div>
        <div className="box-default p-40">
          <div className="flex-column gap-10 h-100">
            <h4>Plus</h4>
            <div className="flex-row flex-align-flexend separation-small">
              <h1>35€</h1>
              <h4>/ano</h4>
            </div>
            <ul className="separation-small">
              <li>
                <CheckCircle />
                Custom branding
              </li>
              <li>
                <CheckCircle />
                Update card designs anytime
              </li>
              <li>
                <CheckCircle />
                Employees can update their details
              </li>
              <li>
                <CheckCircle />
                Up to 50 cards
              </li>
              <li>
                <CheckCircle />
                Up to 5 tickets
              </li>
            </ul>
            <div className="m-t-auto">
              <Buttons value="Subcrever" onClick={() => setPlanId(1)} />
            </div>
          </div>
        </div>
        <div className="box-default p-40">
          <div className="flex-column gap-10 h-100">
            <h4>Premium</h4>
            <div className="flex-row flex-align-flexend separation-small">
              <h1>50€</h1>
              <h4>/ano</h4>
            </div>
            <ul className="separation-small">
              <li>
                <CheckCircle />
                Custom branding
              </li>
              <li>
                <CheckCircle />
                Update card designs anytime
              </li>
              <li>
                <CheckCircle />
                Designated admin to control card details
              </li>
              <li>
                <CheckCircle />
                Bulk management features
              </li>
              <li>
                <CheckCircle />
                Priority customer service
              </li>
              <li>
                <CheckCircle />
                Up to 200 cards
              </li>
              <li>
                <CheckCircle />
                Up to 15 tickets
              </li>
            </ul>
            <div className="m-t-auto">
              <Buttons value="Subcrever" onClick={() => setPlanId(1)} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
/* <h1>Pacotes</h1>
<div className="flex-row is-centered g20 separation">
  <div className={anual ? "inactive" : null}>
    <h3>Individual</h3>
  </div>
  <label className="switch">
    <input type="checkbox" onClick={togglePrice} />
    <span className="slider"></span>
  </label>
  <div className={anual ? null : "inactive"}>
    <h3>Organizacional</h3>
  </div>
</div>
<div
  className="flex-row is-between is-nowrap g20 item-stretch"
  style={anual ? { display: "none" } : null}
>
  <div className="box-default card">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Standard</h4>
      <div className="flex-row item-baseline">
        <h1>20€</h1>
        <h4>/mês</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Employees can update their details
        </li>
        <li>
          <CheckCircle />
          Up to 20 cards
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default"
          buttonSize="btn-small"
          onClick={() => setPlanId(1)}
        />
      </div>
    </div>
  </div>
  <div className="box-default card primary">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Business</h4>
      <div className="flex-row item-baseline">
        <h1>35€</h1>
        <h4>/mês</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Employees can update their details
        </li>
        <li>
          <CheckCircle />
          Up to 50 cards
        </li>
        <li>
          <CheckCircle />
          Up to 5 tickets
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default secondary-bg"
          buttonSize="btn-small"
          onClick={() => setPlanId(2)}
        />
      </div>
    </div>
  </div>
  <div className="box-default card">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Premium</h4>
      <div className="flex-row item-baseline">
        <h1>50€</h1>
        <h4>/mês</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Designated admin to control card details
        </li>
        <li>
          <CheckCircle />
          Bulk management features
        </li>
        <li>
          <CheckCircle />
          Priority customer service
        </li>
        <li>
          <CheckCircle />
          Up to 200 cards
        </li>
        <li>
          <CheckCircle />
          Up to 15 tickets
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default"
          buttonSize="btn-small"
          onClick={() => setPlanId(3)}
        />
      </div>
    </div>
  </div>
</div>
<div
  className="flex-row is-between is-nowrap g20 item-stretch"
  style={anual ? null : { display: "none" }}
>
  <div className="box-default card">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Standard</h4>
      <div className="flex-row item-baseline">
        <h1>230€</h1>
        <h4>/ano</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Employees can update their details
        </li>
        <li>
          <CheckCircle />
          Up to 20 cards
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default"
          buttonSize="btn-small"
          onClick={() => setPlanId(4)}
        />
      </div>
    </div>
  </div>
  <div className="box-default card primary">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Business</h4>
      <div className="flex-row item-baseline">
        <h1>400€</h1>
        <h4>/ano</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Employees can update their details
        </li>
        <li>
          <CheckCircle />
          Up to 50 cards
        </li>
        <li>
          <CheckCircle />
          Up to 5 tickets
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default secondary-bg"
          buttonSize="btn-small"
          onClick={() => setPlanId(5)}
        />
      </div>
    </div>
  </div>
  <div className="box-default card">
    <div className="flex-column item-flexstart p0-20 g10 is-fullheight">
      <h4>Premium</h4>
      <div className="flex-row item-baseline">
        <h1>580€</h1>
        <h4>/ano</h4>
      </div>
      <ul className="text-left">
        <li>
          <CheckCircle />
          Custom branding
        </li>
        <li>
          <CheckCircle />
          Update card designs anytime
        </li>
        <li>
          <CheckCircle />
          Designated admin to control card details
        </li>
        <li>
          <CheckCircle />
          Bulk management features
        </li>
        <li>
          <CheckCircle />
          Priority customer service
        </li>
        <li>
          <CheckCircle />
          Up to 200 cards
        </li>
        <li>
          <CheckCircle />
          Up to 15 tickets
        </li>
      </ul>
      <div className="is-fullwidth m-t-auto">
        <Buttons
          value="Começar"
          buttonStyle="btn-default"
          buttonSize="btn-small"
          onClick={() => setPlanId(6)}
        />
      </div>
    </div>
  </div>
</div> */
