import React from "react";

const STYLES = ["primary", "secondary", "ticket"];
const SIZES = ["", "big", "full-width", "small"];

export const Buttons = ({
  onClick,
  type,
  value,
  icon,
  buttonStyle,
  buttonSize,
  pushBottom,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  return (
    <button
      onClick={onClick}
      type={type}
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${
        pushBottom ? "m-t-auto" : false
      } ${
        icon != null
          ? "flex-row flex-align-center flex-justify-center gap-20"
          : false
      }`}
    >
      {icon}
      {value}
    </button>
  );
};
