import React, { useEffect, useState } from "react";
import { Buttons } from "./buttons";
import { TextInput } from "./inputs";
import MBWay from "../img/payment/mbway_icon.svg";
import PayPal from "../img/payment/paypal_icon.svg";
import Credit from "../img/payment/card_icon.svg";
import Logo from "../img/vipainfinity-logo.svg";
import Companies from "../img/companies.svg";
import { useNavigate, useLocation } from "react-router-dom";
import axiosConfig from "../../providers/axiosConfig";
import {
  CheckCircle,
  Close,
  CreditCard,
  DateRange,
  Password,
  Phone,
} from "@mui/icons-material";

export const PricingForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [phone, setPhone] = useState("");
  const [payment_method, setPaymentMethod] = useState("");
  const [isPhoneInput, setPhoneInput] = useState(false);

  // const togglePhoneInput = () => {
  //   setPhoneInput(!isPhoneInput);
  // };
  const [isCardInput, setCardInput] = useState(false);
  // const toggleCardInput = () => {
  //   setCardInput(!isCardInput);
  // };
  const [userData, setUserdata] = useState({});

  useEffect(() => {
    axiosConfig.get("/me").then((res) => {
      setUserdata({
        client_id: res.data.id,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
      });
    });
  }, []);

  function sendToApi(event) {
    event.preventDefault();
    axiosConfig.get("/me/subscription").then((res) => {
      if (res.data.subscription != null) {
        const plan_data = {
          subscription_plan_id: res.data.subscription.subscription_plan.id,
          status: "canceled",
        };
        const user_data = {
          client_id: userData.client_id,
          subscription_plan_id: location.state.data.subscription_plan_id,
          payment_method: payment_method,
          anual: true,
        };
        axiosConfig
          .put("/me/subscription/" + res.data.subscription.id, plan_data)
          .then((res) => {
            axiosConfig.post("/client_subscription", user_data).then((res) => {
              navigate("/dashboard");
            });
          });
      } else {
        const newuser_data = {
          client_id: userData.client_id,
          subscription_plan_id: location.state.data.subscription_plan_id,
          payment_method: payment_method,
          anual: true,
        };
        // console.log(newuser_data)d;
        axiosConfig.post("/client_subscription", newuser_data).then((res) => {
          navigate("/dashboard");
        });
      }
    });
  }
  return (
    <div className="section h-100 z-index-1">
      <div className="text-center logo separation">
        <img src={Logo} alt="" />
      </div>
      <div className="flex-row w-100 flex-justify-between flex-align-center">
        <h1>Pagamento</h1>
        <Close fontSize="large" />
      </div>

      <form className="grid-2-3 p-20 gap-20" onSubmit={sendToApi}>
        <div className="flex-column box-default gap-20 p-40 flex-grow">
          <h2>Sumário de Compra</h2>
          <div className="flex-row flex-align-center gap-20">
            <div className="icon-round small">
              <img src={require("../img/person.jpg")} alt="" />
            </div>
            <p>{userData.first_name + " " + userData.last_name}</p>
          </div>
          <h4>Plano Anual</h4>
          <div className="flex-row flex-justify-between">
            <p>1 Plano Standard</p>
            <p>€15/ano</p>
          </div>
          <div className="w-100 text-right">
            <p>
              <b>Total</b> €15,00
            </p>
          </div>
          <div className="flex-row w-100 flex-justify-around gap-20 sepration-small">
            <div className="pay_method">
              <input
                type="radio"
                name="radio"
                value={"mb_way"}
                onClick={() => {
                  setPhoneInput(true);
                  setCardInput(false);
                }}
                onChange={(event) => {
                  setPaymentMethod(event.target.value);
                }}
              />
              <span className="checkmark"></span>
              <div>
                <img src={MBWay} alt="" />
              </div>
            </div>
            <div className="pay_method">
              <input
                type="radio"
                name="radio"
                value={"paypal"}
                onClick={() => {
                  setPhoneInput(false);
                  setCardInput(false);
                }}
                onChange={(event) => {
                  setPaymentMethod(event.target.value);
                }}
              />
              <span className="checkmark"></span>
              <div>
                <img src={PayPal} alt="" />
              </div>
            </div>
            <div className="pay_method">
              <input
                type="radio"
                name="radio"
                value={"mb"}
                onClick={() => {
                  setPhoneInput(false);
                  setCardInput(true);
                }}
                onChange={(event) => {
                  setPaymentMethod(event.target.value);
                }}
              />
              <span className="checkmark"></span>
              <div>
                <img src={Credit} alt="" />
              </div>
            </div>
          </div>
          <div>
            <h2>Detalhes de Pagamento</h2>
            <TextInput
              inputSize={"full-width"}
              icon={<Phone />}
              placeholder={"Número de telefone"}
              style={isPhoneInput ? { display: "block" } : { display: "none" }}
              onChange={(event) => console.log(event.target.value)}
            />
            <div
              className="flex-column gap-20"
              style={isCardInput ? { display: "flex" } : { display: "none" }}
            >
              <TextInput
                icon={<CreditCard />}
                type={"text"}
                placeholder={"Número do Cartão de Crédito"}
              />
              <div className="flex-row gap-20">
                <TextInput
                  inputSize={"input-adaptive"}
                  icon={<DateRange />}
                  type={"text"}
                  placeholder={"Validade do cartão MM/AA"}
                />
                <TextInput
                  inputSize={"input-adaptive"}
                  icon={<Password />}
                  type={"number"}
                  placeholder={"Código CVC"}
                />
                <TextInput
                  inputSize={"input-adaptive"}
                  type={"text"}
                  placeholder={"Código Postal"}
                />
              </div>
            </div>
          </div>

          <Buttons
            buttonSize={"input-adaptive"}
            type={"submit"}
            value={"Confirmar"}
          />
        </div>
        <div className="box-default flex-column p-40 gap-20 flex-grow h-fitcontent">
          <h1>Standard</h1>
          <h1>400€</h1>
          <ul className="separation-small">
            <li>
              <CheckCircle />
              Custom branding
            </li>
            <li>
              <CheckCircle />
              Update card designs anytime
            </li>
            <li>
              <CheckCircle />
              Employees can update their details
            </li>
            <li>
              <CheckCircle />
              Up to 20 cards
            </li>
          </ul>
          <p>
            Confiado por mais de <b>10 000</b> utilizadores e empresas
          </p>
          <img src={Companies} alt="" />
          <p>Aceitamos os seguintes cartões de pagamento</p>
        </div>
      </form>
    </div>
  );
};
