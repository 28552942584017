import React, { useEffect } from "react";
import { useState } from "react";
import { Buttons } from "../assets/components/buttons";
import SideBar from "../assets/components/sidebar";
import { TextInput } from "../assets/components/inputs";
// import axios from "axios";
// import apiUrl, { config } from "../assets/components/apiURL";
import axiosConfig from "../providers/axiosConfig";
import { AccountCircle, Receipt } from "@mui/icons-material";

function Profile(props) {
  // WINDOWS HOOKS
  const [edit, setEdit] = useState(false);
  // const [plan, setPlan] = useState(false);
  // const [invoice, setInvoice] = useState(false);
  // const [history, setHistory] = useState(false);
  // function ToggleScreensOff() {
  //   setDetails(false);
  //   setProfessional(false);
  //   setPlan(false);
  //   setInvoice(false);
  //   setHistory(false);
  // }
  function ToggleEdit() {
    setEdit(!edit);
  }
  // function toggleClass(e) {
  //   let classes = "item";
  //   let els = document.getElementsByClassName("item active");
  //   if (els) {
  //     while (els[0]) {
  //       els[0].classList.remove("active");
  //     }
  //   }
  //   e.target.className = classes.replace("item", "item active");
  //   switch (e.target.id) {
  //     case "details":
  //       ToggleScreensOff();
  //       setDetails(true);
  //       break;
  //     case "professional":
  //       ToggleScreensOff();
  //       setProfessional(true);
  //       break;
  //     case "plan":
  //       ToggleScreensOff();
  //       setPlan(true);
  //       break;
  //     case "invoice":
  //       ToggleScreensOff();
  //       setInvoice(true);
  //       break;
  //     case "history":
  //       ToggleScreensOff();
  //       setHistory(true);
  //       break;

  //     default:
  //       break;
  //   }
  // }
  // USER DETAILS
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birth_date, setBirthDate] = useState("");
  // const [password, setPassword] = useState("");

  // PROFESSIONAL DETAILS
  const [title, setTitle] = useState("");
  const [area, setArea] = useState("");
  const [personal_website, setPersonalWebsite] = useState("");
  const [phone_number, setPhone] = useState("");
  // PLAN DETAILS
  const [plan_name, setPlanName] = useState("");
  // const [payment_method, setPaymentMethod] = useState("");
  const [updated_at, setUpdatedAt] = useState("");
  const [created_at, setCreatedAt] = useState("");
  const [status, setStatus] = useState("");
  const [plan_value_anual, setPlanValueAnual] = useState("");

  // GET CLIENT DATA FROM API
  useEffect(() => {
    (async () => {
      await axiosConfig.get("/me").then((res) => {
        // setId(res.data.id);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setEmail(res.data.email);
        setBirthDate(res.data.birth_date);
        // setPassword(res.data.password);
        setTitle(res.data.title);
        setArea(res.data.area);
        setPersonalWebsite(res.data.personal_website);
        setPhone(res.data.phone_number);
        axiosConfig.get("/me/subscription").then((res) => {
          if (res.data.subscription != null) {
            // setSubscriptionId(res.data.subscription.id);
            // setPlanId(res.data.subscription.subscription_plan.id);
            // setPaymentMethod(res.data.subscription.payment_method);
            setUpdatedAt(res.data.subscription.subscription_end);
            setCreatedAt(res.data.subscription.created_at);
            setStatus(res.data.subscription.status);
            setPlanName(res.data.subscription.subscription_plan.name);
            setPlanValueAnual(
              res.data.subscription.subscription_plan.price_year
            );
          }
        });
      });
    })();
  }, []);

  function UpdateUserData(event) {
    event.preventDefault();
    const user_data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      birth_date: birth_date,
      title: title,
      area: area,
      personal_website: personal_website,
      phone_number: phone_number,
    };
    axiosConfig.put("/me", user_data).then((res) => {
      setEdit(false);
    });
  }

  // function UpdateUserPlan() {
  //   navigate("../dashboard/plan");
  // }
  // function CancelUserPlan() {
  //   const plan_data = {
  //     subscription_plan_id: plan_id,
  //     status: "canceled",
  //   };
  //   axios
  //     .put(apiUrl + "/me/subscription/" + subscription_id, plan_data, config)
  //     .then((res) => {
  //       console.log(res);
  //     });
  // }

  return (
    <main className="h-100 flex-row">
      <SideBar />
      <div className="grid-3 p-40 border-box w-100 overflow-hidden">
        <div className="bg_lights">
          <div></div>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="h-100">
          <h3 className="separation">Opções</h3>
          <div className="profile-navigation">
            <div id="details" className="item active">
              Meus Detalhes
            </div>
            {/* <div id="plan" className="item" onClick={(e) => toggleClass(e)}>
              Plano
            </div>
            <div id="invoice" className="item" onClick={(e) => toggleClass(e)}>
              Dados de Faturação
            </div> */}
          </div>
        </div>
        {edit ? (
          <form
            onSubmit={UpdateUserData}
            style={edit ? { display: "flex" } : { display: "none" }}
            className={"flex-row gap-20 h-50"}
          >
            <div className="flex-column flex-align-stretch flex-grow">
              <h3 className="separation">Dados do Perfil</h3>

              <div className="flex-row gap-20 w-100 flex-align-stretch flex-grow">
                <div className="box-default p-40 flex-column gap-40">
                  <div className="icon-round editable">
                    <AccountCircle />
                  </div>
                </div>

                <div className="box-default p-40 flex-column gap-40 flex-grow">
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Nome:</b>
                    </p>
                    <TextInput
                      value={first_name}
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Sobrenome:</b>
                    </p>
                    <TextInput
                      value={last_name}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Email:</b>
                    </p>
                    <TextInput
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Data de nascimento:</b>
                    </p>
                    <TextInput
                      type={"date"}
                      value={birth_date}
                      onChange={(event) => {
                        setBirthDate(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column flex-align-stretch flex-grow">
              <h3 className="separation">Dados do Profissionais</h3>
              <div className="box-default p-20 flex-column gap-40 flex-grow">
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Título:</b>
                  </p>
                  <TextInput
                    type={"text"}
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Área profissional:</b>
                  </p>
                  {/* <select
                  className="select-primary"
                  defaultValue={area}
                  defaultChecked={area}
                  onChange={(event) => {
                    setArea(event.target.value);
                  }}
                >
                  <option value={"Vendas"}>Vendas</option>
                  <option value={"Marketing"}>Marketing</option>
                  <option value={"Engenheiro"}>Engenheiro</option>
                  <option value={"Designer"}>Designer</option>
                  <option value={"Outro"}>Outro</option>
                </select> */}
                  <TextInput
                    type={"text"}
                    value={area}
                    onChange={(event) => {
                      setArea(event.target.value);
                    }}
                  />
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Telefone:</b>
                  </p>
                  <TextInput
                    type={"number"}
                    value={phone_number}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Website:</b>
                  </p>
                  <TextInput
                    type={"text"}
                    value={personal_website}
                    onChange={(event) => {
                      setPersonalWebsite(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex-row flex-justify-right separation-small">
              <Buttons type={"submit"} value={"Guardar"} />
            </div>
          </form>
        ) : (
          <div
            style={!edit ? { display: "flex" } : { display: "none" }}
            className={"flex-row gap-20 h-50"}
          >
            <div className="flex-column flex-align-stretch flex-grow">
              <h3 className="separation">Dados do Perfil</h3>

              <div className="flex-row gap-20 w-100 flex-align-stretch flex-grow">
                <div className="box-default p-40 flex-column gap-40">
                  <div className="icon-round editable">
                    <AccountCircle />
                  </div>
                </div>

                <div className="box-default p-40 flex-column gap-40 flex-grow">
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Nome:</b>
                    </p>
                    <p>{first_name}</p>
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Sobrenome:</b>
                    </p>
                    <p>{last_name}</p>
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Email:</b>
                    </p>
                    <p>{email}</p>
                  </div>
                  <div className="flex-row flex-justify-left flex-align-center gap-10">
                    <p>
                      <b>Data de nascimento:</b>
                    </p>
                    <p>{birth_date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column flex-align-stretch flex-grow">
              <h3 className="separation">Dados do Profissionais</h3>
              <div className="box-default p-20 flex-column gap-40 flex-grow">
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Título:</b>
                  </p>
                  <p>{title}</p>
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Área profissional:</b>
                  </p>
                  {/* <select
                  className="select-primary"
                  defaultValue={area}
                  defaultChecked={area}
                  onChange={(event) => {
                    setArea(event.target.value);
                  }}
                >
                  <option value={"Vendas"}>Vendas</option>
                  <option value={"Marketing"}>Marketing</option>
                  <option value={"Engenheiro"}>Engenheiro</option>
                  <option value={"Designer"}>Designer</option>
                  <option value={"Outro"}>Outro</option>
                </select> */}
                  <p>{area}</p>
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Telefone:</b>
                  </p>
                  <p>{phone_number}</p>
                </div>
                <div className="flex-row flex-justify-left gap-10">
                  <p>
                    <b>Website:</b>
                  </p>
                  <p>{personal_website}</p>
                </div>
              </div>
            </div>
            <div className="flex-row flex-justify-right separation-small">
              <Buttons type={"button"} value={"Editar"} onClick={ToggleEdit} />
            </div>
          </div>
        )}
        <div
          style={{ display: "none" }}
          // style={history ? { display: "block" } : { display: "none" }}
          className="flex-grow"
        >
          <div>
            <h3 className="separation">Faturas</h3>
            <div className="flex-column w-100 gap-20">
              <div className="box-default p-20-40 flex-row flex-justify-between flex-align-center gap-20">
                <p>Fatura Nº 234657</p>
                <p>15.00€</p>
                <p>Quinta-feira, 13 de Julho 2022</p>
                <p>Pagamento Confirmado</p>
                <div>
                  <Receipt />
                </div>
              </div>
              <div className="box-default p-20-40 flex-row flex-justify-between flex-align-center gap-20">
                <p>Fatura Nº 234657</p>
                <p>15.00€</p>
                <p>Quinta-feira, 13 de Julho 2022</p>
                <p>Pagamento Confirmado</p>
                <div>
                  <Receipt />
                </div>
              </div>
              <div className="box-default p-20-40 flex-row flex-justify-between flex-align-center gap-20">
                <p>Fatura Nº 234657</p>
                <p>15.00€</p>
                <p>Quinta-feira, 13 de Julho 2022</p>
                <p>Pagamento Confirmado</p>
                <div>
                  <Receipt />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form
          style={{ display: "none" }}
          // style={invoice ? { display: "block" } : { display: "none" }}
          className="flex-grow"
        >
          <h3 className="separation">Dados do Faturação</h3>
          <div className="flex-row flex-justify-right separation-small">
            <Buttons type={"submit"} value={"Salvar"} />
          </div>
          <div className="box-default p-20 flex-column gap-20">
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Morada:</b>
              </p>
              <TextInput />
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Contribuinte:</b>
              </p>
              <TextInput />
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Código postal:</b>
              </p>
              <TextInput />
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Localidade:</b>
              </p>
              <TextInput />
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Cidade:</b>
              </p>
              <TextInput />
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>País:</b>
              </p>
              <TextInput />
            </div>
          </div>
        </form>
        <div
          style={{ display: "none" }}
          // style={plan ? { display: "block" } : { display: "none" }}
          className="flex-grow"
        >
          <h3 className="separation">Planos</h3>
          <div className="box-default p-20 flex-column gap-20">
            <div className="flex-row flex-justify-right separation-small">
              <Buttons type={"submit"} value={"Editar"} />
            </div>
            <h4>Plano</h4>
            <h2>{plan_name}</h2>
            <hr className="w-100" />
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Data de início:</b>
              </p>
              <p>{created_at.split("T")[0]}</p>
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Próxima Mensalidade:</b>
              </p>
              <p>{updated_at.split(" ")[0]}</p>
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Valor:</b>
              </p>
              <p>{plan_value_anual}€</p>
            </div>
            <div className="flex-row flex-justify-left gap-10">
              <p>
                <b>Método de pagamento:</b>
              </p>
            </div>
            <div className="flex-row flex-justify-right gap-10">
              <p>
                <b>Estado:</b>
              </p>
              <p>{status}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Profile;
