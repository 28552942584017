import React from "react";
import { PricingForm } from "../assets/components/pricingform";
function Payment() {
  return (
    <main className="h-100">
      <div className="bg_lights">
        <img src={require("../assets/img/index/bg_lights.png")} alt="" />
      </div>

      <PricingForm />
    </main>
  );
}

export default Payment;
