import React, { useState } from "react";
import "../css/main.css";
import vipalogo from "../assets/img/vipainfinity-logo.svg";
import bg from "../assets/img/background_login.svg";
import { BadgeRounded, Email, Lock } from "@mui/icons-material";
import { Buttons } from "../assets/components/buttons";
import { TextInput } from "../assets/components/inputs";
import { Link, useNavigate } from "react-router-dom";
import axiosConfig from "../providers/axiosConfig";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let token, bearer;
  const navigate = useNavigate();
  function SendToApi(event) {
    event.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      terms_conditions: true,
    };
    (async () => {
      await axiosConfig.post("/register", data).then((res) => {
        localStorage.clear();
        token = res.data.access_token;
        bearer = res.data.token_type;
        localStorage.setItem("token", bearer + " " + token);
        navigate("/dashboard/createticket");
      });
    })();
  }

  return (
    <main className="is-fullheight ovr-hd">
      <div className="bg">
        <img src={bg} alt="" />
      </div>
      <section className="is-fullheight flex-row is-nowrap item-stretch is-centered">
        <div className="is-fullwidth flex-column is-centered">
          <Link to="/">
            <div className="logo big">
              <img alt="" src={vipalogo} />
            </div>
          </Link>
        </div>

        <div className="box-default-0 is-fullwidth text-centered">
          <form
            onSubmit={SendToApi}
            className="flex-column is-halfwidth is-fullheight g20 p20 is-centered m-center"
          >
            <h1 className="text-separation-small">Registo</h1>
            <div className="flex-row is-between g20 is-nowrap is-fullwidth">
              <TextInput
                placeholder="Primeiro Nome"
                id={"first_name"}
                inputSize={"input-adaptive"}
                icon={<BadgeRounded />}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
              <TextInput
                placeholder="Sobrenome"
                id={"last_name"}
                inputSize={"input-adaptive"}
                icon={<BadgeRounded />}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
            <TextInput
              placeholder="E-mail"
              id={"email"}
              inputSize={"input-adaptive"}
              icon={<Email />}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextInput
              type="password"
              placeholder="Password"
              id={"password"}
              inputSize={"input-adaptive"}
              icon={<Lock />}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Buttons
              value="Registar"
              buttonStyle="btn-default"
              buttonSize="btn-adaptive"
              type="submit"
            />
            <p className="text-separation">
              Já possui uma conta?
              <Link style={{ color: "#9059E9" }} to="/login">
                <b> Log in</b>
              </Link>
            </p>
          </form>
        </div>
      </section>
    </main>
  );
};
export default Register;
