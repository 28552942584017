import {
  Business,
  Email,
  Language,
  LocationOn,
  Phone,
  Public,
  Tag,
} from "@mui/icons-material";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import apiUrl, { config } from "./apiURL";
import axiosConfig from "../../providers/axiosConfig";
import { Buttons } from "./buttons";
import { PhotoInput, TextInput } from "./inputs";
import SideBar from "./sidebar";
const CreateBusiness = () => {
  const [client_id, setClientId] = useState("");
  const [company_name, setCompanyName] = useState("");
  // const [company_area, setCompanyArea] = useState("");
  const [company_address, setCompanyAddress] = useState("");
  const [company_country, setCompanyCountry] = useState("");
  const [company_nif, setCompanyNif] = useState("");
  const [company_phone, setCompanyPhone] = useState("");
  const [company_website, setCompanieWebsite] = useState("");
  const [company_email, setCompanyEmail] = useState("");
  // const [company_size, setCompanySize] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axiosConfig.get("/me").then((res) => {
      setClientId(res.data.id);
    });
  }, []);
  function SendBusinessApi(event) {
    event.preventDefault();

    const business_data = {
      name: company_name,
      email: company_email,
      address: company_address,
      company_country: company_country,
      phone_number: company_phone,
      nif: company_nif,
      area: "design",
      website: company_website,
      size: "small",
      client_id: client_id,
    };
    axiosConfig.post("/company/" + client_id, business_data).then((res) => {
      navigate("/dashboard/business");
    });
  }
  return (
    <main className="h-100 flex-row">
      <SideBar />
      <div className="flex-column w-100 p-40">
        <h1>Criar Empresa</h1>
        <form
          className="box-default p-20 align-self w-50"
          onSubmit={SendBusinessApi}
        >
          <div className="flex-column gap-20 ">
            <div className="flex-column">
              <PhotoInput />
            </div>
            <div className="flex-row gap-40 separation-small">
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  placeholder="Nome"
                  icon={<Business />}
                  onChange={(event) => {
                    setCompanyName(event.target.value);
                  }}
                />
              </div>
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Email"
                  icon={<Email />}
                  onChange={(event) => {
                    setCompanyEmail(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row gap-40 separation-small">
              {/* <div className="is-grow">
                <div className="input-secondary input-adaptive">
                  <select
                    className="input-default"
                    placeholder="Area"
                    onChange={(event) => {
                      setCompanyArea(event.target.value);
                    }}
                  >
                    <option value={"sales"}>Sales</option>
                    <option value={"marketing"}>Marketing</option>
                    <option value={"engineer"}>Engineer</option>
                    <option value={"design"}>Design</option>
                    <option value={"other"}>Other</option>
                  </select>
                  <div className="input-icon">
                    <Work />
                  </div>
                </div>
              </div> */}
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="País"
                  icon={<Public />}
                  onChange={(event) => {
                    setCompanyCountry(event.target.value);
                  }}
                />
              </div>
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Número de identificação fiscal"
                  icon={<Tag />}
                  onChange={(event) => {
                    setCompanyNif(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row gap-40 separation-small">
              {/* <div className="is-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Tamanho"
                  icon={<Group />}
                  onChange={(event) => {
                    setCompanySize(event.target.value);
                  }}
                />
              </div> */}
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Morada"
                  icon={<LocationOn />}
                  onChange={(event) => {
                    setCompanyAddress(event.target.value);
                  }}
                />
              </div>
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Cod. Postal"
                  icon={<LocationOn />}
                  onChange={(event) => {
                    setCompanyAddress(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row gap-40 separation-small">
              <div className="flex-grow">
                <TextInput
                  type={"text"}
                  inputSize={"input-adaptive"}
                  placeholder="Website"
                  icon={<Language />}
                  onChange={(event) => {
                    setCompanieWebsite(event.target.value);
                  }}
                />
              </div>
              <div className="flex-grow">
                <TextInput
                  type={"number"}
                  inputSize={"input-adaptive"}
                  placeholder="Telefone"
                  icon={<Phone />}
                  onChange={(event) => {
                    setCompanyPhone(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex-row gap-40 separation-small flex-justify-center">
              <Buttons
                type={"button"}
                buttonStyle={"secondary"}
                value={"Cancelar"}
                onClick={() => navigate("/dashboard/business")}
              />
              <Buttons type={"submit"} value={"Guardar"} />
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CreateBusiness;
