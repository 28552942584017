import React, { useEffect, useState } from "react";
import { Buttons } from "./buttons";
import { PhotoInput, TextInput } from "./inputs";
import axiosConfig from "../../providers/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { BadgeRounded, Email, Phone, Work } from "@mui/icons-material";

const EditWorker = () => {
  const location = useLocation();
  const [worker_id, setWorkerId] = useState(0);
  // const [company_id, setCompanyId] = useState(0);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [post, setPost] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    setWorkerId(location.state.worker_id);
    // setCompanyId(location.state.selected_company);

    axiosConfig.get("/worker/" + location.state.worker_id).then((res) => {
      setFirstName(res.data.first_name);
      setLastName(res.data.last_name);
      setPost(res.data.post);
      setEmail(res.data.email);
      setPhone(res.data.phone_number);
      // console.log(worker_id);
    });
  }, [location.state.worker_id]);
  async function EditWorkerAPI(event) {
    event.preventDefault();
    const data = {
      first_name: first_name,
      last_name: last_name,
      post: post,
      email: email,
      phone_number: phone,
    };
    await axiosConfig.put("/worker/" + worker_id, data).then((res) => {
      navigate("/dashboard");
    });
  }
  return (
    <main className="ovr-hd is-fullheight">
      <section className="is-section is-fullheight flex-column is-centered">
        <form
          className="box-default is-halfwidth m-center flex-column item-flexstart p20"
          onSubmit={EditWorkerAPI}
        >
          <h1 className="text-separation">Editar Trabalhador</h1>
          <div className="flex-row is-nowrap g20  is-fullwidth">
            <div className="flex-column is-centered round-img big b1-white">
              <PhotoInput />
            </div>
            <div className="flex-column g20 item-stretch is-grow">
              <TextInput
                inputSize={"input-adaptive"}
                value={first_name}
                icon={<BadgeRounded />}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
              <TextInput
                inputSize={"input-adaptive"}
                value={last_name}
                icon={<BadgeRounded />}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <TextInput
                inputSize={"input-adaptive"}
                value={post}
                icon={<Work />}
                onChange={(event) => {
                  setPost(event.target.value);
                }}
              />
              <TextInput
                inputSize={"input-adaptive"}
                value={email}
                icon={<Email />}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <TextInput
                type="number"
                inputSize={"input-adaptive"}
                value={phone}
                icon={<Phone />}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-column is-nowrap g20 is-centered is-fullwidth m-t-20">
            <Buttons
              value={"Criar"}
              type={"submit"}
              buttonSize={"btn-adaptive"}
            />
            <Buttons
              value={"Cancelar"}
              buttonStyle={"btn-secondary"}
              type={"button"}
              buttonSize={"btn-adaptive"}
            />
          </div>
        </form>
      </section>
    </main>
  );
};
export default EditWorker;
