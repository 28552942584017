import React from "react";

function CardTemplateButton({ onClick, src }) {
  return (
    <div className="card-vert" onClick={onClick}>
      <img src={src} alt="" />
    </div>
  );
}

export default CardTemplateButton;
