import axios from "axios";
import apiUrl from "./apiURL";

const axiosConfig = axios.create({
  baseURL: apiUrl,
});

if (localStorage.getItem("token")) {
  const { access_token } = JSON.parse(localStorage.getItem("token"));

  axiosConfig.defaults.headers.common = {
    Authorization: `Bearer ${access_token}`,
  };
}

axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    let res = err.response && err.response.data;
    if (res) {
      if (res.message) {
        err.message = res.message;
      } else {
        err.message = `api.errors.${res.error}`;
      }
    }

    return Promise.reject(err);
  }
);

export default axiosConfig;
