import React, { useEffect, useState } from "react";
import "../css/main.css";
import { BadgeRounded, Close, Email, Lock } from "@mui/icons-material";
import { Buttons } from "../assets/components/buttons";
import GoogleLogo from "../assets/img/login/google_logo.png";
import FacebookLogo from "../assets/img/login/facebook_logo.png";
import { TextInput } from "../assets/components/inputs";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../providers/axiosConfig";

function Login({ open, openfunction }) {
  const [login, setForm] = useState(false);
  function toggleForm() {
    setForm(!login);
    setRegister(false);
  }
  const [register, setRegister] = useState(false);
  function toggleRegister() {
    setRegister(!register);
  }
  useEffect(() => {
    if (!open) {
      setForm(false);
    }
  }, [open]);

  const [login_email, setLoginEmail] = useState("");
  const [login_password, setLoginPassword] = useState("");
  let token, bearer;
  const navigate = useNavigate();

  async function LoginUser(event) {
    event.preventDefault();
    const data = {
      email: login_email,
      password: login_password,
    };
    const loginRequest = await axiosConfig.post("/login", data);

    if (loginRequest.status === 200) {
      token = loginRequest.data.access_token;
      bearer = loginRequest.data.token_type;
      localStorage.setItem("token", bearer + " " + token);
      axiosConfig.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  async function RegisterUser(event) {
    event.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      terms_conditions: true,
    };

    await axiosConfig
      .post("/register", data)
      .then((res) => {
        window.localStorage.clear();
        token = res.data.access_token;
        bearer = res.data.token_type;
        localStorage.setItem("token", bearer + " " + token);
      })
      .then(navigate("/dashboard/"));
  }
  return (
    <div
      className="blur"
      style={open ? { display: "block" } : { display: "none" }}
    >
      <div className={`flipper-wrap`}>
        <div
          className={`position-relative flipper ${login ? "flip" : "default"}`}
          id="flipper"
        >
          <form className="front h-100" onSubmit={LoginUser}>
            <div className="box-default flex-column flex-align-center gap-10 p-40">
              <div className="flex-row w-100 flex-justify-right">
                <Close onClick={openfunction} />
              </div>

              <h1 className="title" onClick={() => (open = false)}>
                Login
              </h1>
              <TextInput
                icon={<Email />}
                type={"text"}
                placeholder={"Email"}
                inputSize={"input-adaptive"}
                onChange={(event) => setLoginEmail(event.target.value)}
              />
              <TextInput
                icon={<Lock />}
                type={"password"}
                placeholder={"Password"}
                inputSize={"input-adaptive"}
                onChange={(event) => setLoginPassword(event.target.value)}
              />
              {/* <div
                className={"separation-small"}
                style={{ cursor: "pointer" }}
                onClick={toggleForm}
              >
                Não tem conta?<span> Registe-se</span>
              </div> */}
              <Buttons type={"submit"} value="Entrar" pushBottom={true} />
            </div>
          </form>
          <div className="back h-100">
            <div className="box-default flex-column flex-align-center gap-10 p-40">
              <div className="flex-row w-100 flex-justify-right">
                <Close onClick={openfunction} />
              </div>
              <h1 className="title">Registo</h1>
              <h4 className="separation-small">Bem-vindo(a) à Vipa Infinity</h4>
              <div
                className="flex-column flex-align-center gap-20 w-100 text-center"
                style={register ? { display: "none" } : { display: "flex" }}
              >
                <Buttons
                  icon={
                    <img src={FacebookLogo} style={{ width: "24px" }} alt="" />
                  }
                  value={"Continuar com Facebook"}
                  buttonStyle={"secondary"}
                  buttonSize={"full-width"}
                />
                <Buttons
                  icon={
                    <img src={GoogleLogo} style={{ width: "24px" }} alt="" />
                  }
                  value={"Continuar com Google"}
                  buttonStyle={"secondary"}
                  buttonSize={"full-width"}
                />
                <p onClick={toggleRegister} style={{ cursor: "pointer" }}>
                  Continuar com e-mail
                </p>
                <div
                  className={"separation-small"}
                  style={{ cursor: "pointer" }}
                  onClick={toggleForm}
                >
                  Já possui uma conta? <span>Log in</span>
                </div>
                <p>
                  Ao prosseguir com resgisto concorda com os Termos e Condições
                  e a Política de Privacidade
                </p>
              </div>
              <form onSubmit={RegisterUser}>
                <div
                  className="flex-column flex-align-center gap-20 w-100"
                  style={register ? { display: "flex" } : { display: "none" }}
                >
                  <div className="flex-row gap-20">
                    <TextInput
                      placeholder="Primeiro Nome"
                      id={"first_name"}
                      inputSize={"input-adaptive"}
                      icon={<BadgeRounded />}
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                    />
                    <TextInput
                      placeholder="Sobrenome"
                      id={"last_name"}
                      inputSize={"input-adaptive"}
                      icon={<BadgeRounded />}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </div>

                  <TextInput
                    placeholder="E-mail"
                    id={"email"}
                    inputSize={"input-adaptive"}
                    icon={<Email />}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <TextInput
                    type="password"
                    placeholder="Password"
                    id={"password"}
                    inputSize={"input-adaptive"}
                    icon={<Lock />}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                  <div
                    className={"separation-small"}
                    style={{ cursor: "pointer" }}
                    onClick={toggleForm}
                  >
                    Já possui uma conta? <span>Log in</span>
                  </div>
                  <div className="flex-row flex-justify-center gap-20 w-100">
                    <Buttons
                      value="Cancelar"
                      pushBottom={true}
                      buttonStyle={"secondary"}
                      onClick={toggleRegister}
                      type={"button"}
                    />
                    <Buttons
                      type={"submit"}
                      value="Registar"
                      pushBottom={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   let token, bearer;
//   const navigate = useNavigate();
//   function SendToApi(event) {
//     event.preventDefault();
//     const data = {
//       email: email,
//       password: password,
//     };
//     axios.post(apiUrl + "/login", data, config).then((res) => {
//       token = res.data.access_token;
//       bearer = res.data.token_type;
//       localStorage.setItem("token", bearer + " " + token);
//       navigate("/dashboard/");
//     });
//     console.log(data);
//   }
//   return (
//     <main className="is-fullheight ovr-hd">
//       <div className="bg">
//         <img src={bg} alt="" />
//       </div>
//       <section className="is-fullheight flex-row is-nowrap item-stretch is-centered">
//         <div className="is-fullwidth flex-column is-centered">
//           <Link to="/">
//             <div className="logo big">
//               <img alt="" src={vipalogo} />
//             </div>
//           </Link>
//         </div>

//         <form
//           className="box-default-0 is-fullwidth text-centered"
//           onSubmit={SendToApi}
//         >
//           <div className="flex-column is-halfwidth is-fullheight g20 p20 is-centered m-center">
//             <h1 className="text-separation-small">Log in</h1>
//             <TextInput
//               placeholder="Email"
//               id={"user"}
//               inputSize={"input-adaptive"}
//               icon={<Person />}
//               onChange={(event) => setEmail(event.target.value)}
//             />
//             <TextInput
//               placeholder="Password"
//               type={"password"}
//               id={"password"}
//               inputSize={"input-adaptive"}
//               icon={<Lock />}
//               onChange={(event) => setPassword(event.target.value)}
//             />
//             <Buttons
//               value="Entrar"
//               buttonStyle="btn-default"
//               buttonSize="btn-adaptive"
//             />
//             <p className="text-separation">
//               Não tem conta?{" "}
//               <Link style={{ color: "#9059E9" }} to="/register">
//                 <b> Registe-se</b>
//               </Link>
//             </p>
//           </div>
//         </form>
//       </section>
//     </main>
//   );
// }
export default Login;
