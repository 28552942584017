import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./lib/home";
import Home from "./lib/home";
import Login from "./lib/routes/login";
import DashBoard from "./lib/routes/dashboard";
import Profile from "./lib/routes/profile";
import BusinessView from "./lib/assets/components/businessoverview";
import Register from "./lib/routes/register";
import Plan from "./lib/routes/plan";
import Payment from "./lib/routes/payment";
import CreateBusiness from "./lib/assets/components/createbusiness";
import EditBusiness from "./lib/assets/components/editbusiness";
import EditWorker from "./lib/assets/components/editworker";
import CreateCard from "./lib/routes/createcard";
import CardPreview from "./lib/routes/cardpreview";

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/cardpreview" element={<CardPreview />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard/business" element={<BusinessView />} />
        <Route path="/dashboard/createcard" element={<CreateCard />} />
        <Route path="/dashboard/plan" element={<Plan />} />
        <Route path="/dashboard/payment" element={<Payment />} />
        <Route path="/dashboard/createbusiness" element={<CreateBusiness />} />
        <Route path="/dashboard/editbusiness" element={<EditBusiness />} />
        <Route path="/dashboard/editworker" element={<EditWorker />} />
      </Routes>
    </Router>
  );
}

export default App;
